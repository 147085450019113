import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_AGENT_CONTACT_REQUEST = "TRIGGER_AGENT_CONTACT_REQUEST";
export const TRIGGER_AGENTS_CONTACT_REQUEST = "TRIGGER_AGENTS_CONTACT_REQUEST";
export const TRIGGER_AGENT_BLOCK_REQUEST = "TRIGGER_AGENT_BLOCK_REQUEST";
export const TRIGGER_USER_PROFILE_REQUEST = "TRIGGER_USER_PROFILE_REQUEST";
export const TRIGGER_AGENT_PERSONAL_DETAILS_SAVE = "TRIGGER_AGENT_PERSONAL_DETAILS_SAVE";
export const TRIGGER_AGENT_PHONENUMBER_VERIFY_OTP = "TRIGGER_AGENT_PHONENUMBER_VERIFY_OTP";
export const TRIGGER_AGENT_PHONENUMBER_SUBMIT_OTP = "TRIGGER_AGENT_PHONENUMBER_SUBMIT_OTP";
export const TRIGGER_AGENT_PROFILE_PICTURE_UPLOAD = "TRIGGER_AGENT_PROFILE_PICTURE_UPLOAD";
export const TRIGGER_AGENT_FINANCIAL_DETAILS_EDIT = "TRIGGER_AGENT_FINANCIAL_DETAILS_EDIT";
export const TRIGGER_AGENT_PERSONAL_DETAILS_GET = "TRIGGER_AGENT_PERSONAL_DETAILS_GET";
export const TRIGGER_AGENT_PROFILE_PICTURE_DELETE = "TRIGGER_AGENT_PROFILE_PICTURE_DELETE";
export const TRIGGER_AGENT_CONTACT_EMAIL_VERIFY = "TRIGGER_AGENT_CONTACT_EMAIL_VERIFY";
export const TRIGGER_AGENT_CONTACT_EMAIL_SUBMIT_OTP = "TRIGGER_AGENT_CONTACT_EMAIL_SUBMIT_OTP";

interface ResponseType {
  data: any;
  error?: any;
  loading?: boolean;
}

interface SuccessData {
  data: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    gender?: string;
    isInsured: boolean;
    dateOfBirth: string;
    contactType: string;
    profilePicture?: string;
    phoneVerificationDate: string;
    state: string;
    city: string;
    street: string;
    country: string;
    financial: {
      yearlyIncome: string;
      netWorth: string;
      companyName?: string;
    };
    health: {
      height: string;
      weight: string;
      medicalCondition: string;
      regularMedication: string;
      NicotineInfo: string;
      nicotineInfo?: string;
    };
    activity: [];
  };
}

export interface AgentPersonalInfo {
  data: {
    userId: string;
    profId: string;
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    dateOfBirth: string;
    zipCode: string;
    state: string;
    country: string;
    profilePicture: string;
    streetAddress: string;
    profileProgress: string;
    phoneVerificationDate: string;
  };
}

interface responseType {
  agentContactSuccess: SuccessData;
  agentContactError: ResponseType;
  agentContactBlockSuccess: ResponseType;
  agentConatctBlockError: ResponseType;
  agentContactBasicInfoEditSuccess: ResponseType;
  agentContactBasicInfoEditError: ResponseType;
  agentContactFinancialInfoEditSuccess: ResponseType;
  agentContactFinancialInfoEditError: ResponseType;
  agentContactVerifyOtpSuccess: ResponseType;
  agentContactVerifyOtpError: ResponseType;
  agentContactVerifyEmailSuccess: ResponseType;
  agentContactVerifyEmailError: ResponseType;
  agentContactProfilePictureUploadSuccess: ResponseType;
  agentContactProfilePictureUploadError: ResponseType;
  agentContactOTPSubmitSuccess: ResponseType;
  agentContactOtpSubmitError: ResponseType;
  agentContactEmailOTPSubmitSuccess: ResponseType;
  agentContactEmailOtpSubmitError: ResponseType;
  agentContactProfileInfoError: ResponseType;
  agentContactProfileInfoSucess: AgentPersonalInfo;
  agentContactProfilePictureDeleteError: ResponseType;
  agentContactProfilePictureDeleteSucess: ResponseType;
  agentContacts: { data: any; error: any; loading: boolean };
}

const initialState: responseType = {
  agentContactSuccess: {
    data: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      isInsured: false,
      countryCode: "",
      phoneNumber: "",
      contactType: "",
      phoneVerificationDate: "",
      dateOfBirth: "",
      state: "",
      city: "",
      country: "",
      profilePicture: "",
      street: "",
      financial: {
        yearlyIncome: "",
        netWorth: "",
      },
      health: {
        height: "",
        weight: "",
        medicalCondition: "",
        regularMedication: "",
        NicotineInfo: "",
      },
      activity: [],
    },
  },
  agentContactError: { error: "", data: {} },
  agentContactBlockSuccess: { error: {}, data: {} },
  agentConatctBlockError: { error: {}, data: {} },
  agentContactBasicInfoEditSuccess: { error: {}, data: {} },
  agentContactBasicInfoEditError: { error: {}, data: {} },
  agentContactFinancialInfoEditSuccess: { error: {}, data: {} },
  agentContactFinancialInfoEditError: { error: {}, data: {} },
  agentContactVerifyOtpSuccess: { error: {}, data: {} },
  agentContactVerifyOtpError: { error: {}, data: {} },
  agentContactVerifyEmailSuccess: { error: {}, data: {} },
  agentContactVerifyEmailError: { error: {}, data: {} },
  agentContactProfilePictureUploadSuccess: { error: {}, data: {} },
  agentContactProfilePictureUploadError: { error: {}, data: {} },
  agentContactOTPSubmitSuccess: { error: {}, data: {} },
  agentContactOtpSubmitError: { error: {}, data: {} },
  agentContactEmailOTPSubmitSuccess: { error: {}, data: {} },
  agentContactEmailOtpSubmitError: { error: {}, data: {} },
  agentContactProfileInfoSucess: {
    data: {
      userId: "",
      profId: "",
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      dateOfBirth: "",
      zipCode: "",
      state: "",
      country: "",
      profilePicture: "",
      streetAddress: "",
      profileProgress: "",
      phoneVerificationDate: "",
    },
  },
  agentContactProfileInfoError: { error: {}, data: {} },
  agentContactProfilePictureDeleteError: { error: {}, data: {} },
  agentContactProfilePictureDeleteSucess: { error: {}, data: {} },
  agentContacts: { loading: false, data: {}, error: {} },
};

export const agentContactSlice = createSlice({
  name: "agentContact",
  initialState,
  reducers: {
    agentContactSuccess: (state, action: PayloadAction<any>) => {
      state.agentContactSuccess = { data: action.payload };
    },
    contactBlockSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactBlockSuccess = { data: action.payload, error: {} };
    },
    contactBlockError: (state, action: PayloadAction<Error>) => {
      state.agentConatctBlockError = { error: action.payload, data: {} };
    },
    agentContactError: (state, action: PayloadAction<Error>) => {
      state.agentContactError = { error: action.payload, data: {} };
    },
    agentContactBasicInfoEditSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactBasicInfoEditSuccess = { data: action.payload, error: {} };
    },
    agentContactBasicInfoEditError: (state, action: PayloadAction<any>) => {
      state.agentContactBasicInfoEditError = { error: action.payload, data: {} };
    },
    agentContactFinancialInfoEditSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactFinancialInfoEditSuccess = { data: action.payload, error: {} };
    },
    agentContactFinancialInfoEditError: (state, action: PayloadAction<any>) => {
      state.agentContactFinancialInfoEditError = { error: action.payload, data: {} };
    },
    agentContactPhoneOtpSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactVerifyOtpSuccess = { data: action.payload, error: {} };
    },
    agentContactPhoneOtpEditError: (state, action: PayloadAction<any>) => {
      state.agentContactVerifyOtpError = { error: action.payload, data: {} };
    },
    agentContactEmailOtpSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactVerifyEmailSuccess = { data: action.payload, error: {} };
    },
    agentContactEmailOtpEditError: (state, action: PayloadAction<any>) => {
      state.agentContactVerifyEmailError = { error: action.payload, data: {} };
    },
    agentContactOTPSubmitSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactOTPSubmitSuccess = { data: action.payload, error: {} };
    },
    agentContactOtpSubmitError: (state, action: PayloadAction<any>) => {
      state.agentContactOtpSubmitError = { error: action.payload, data: {} };
    },
    agentContactEmailOTPSubmitSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactEmailOTPSubmitSuccess = { data: action.payload, error: {} };
    },
    agentContactEmailOtpSubmitError: (state, action: PayloadAction<any>) => {
      state.agentContactEmailOtpSubmitError = { error: action.payload, data: {} };
    },
    agentContactProfilePictureUploadSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentContactProfilePictureUploadSuccess = { data: action.payload, error: {} };
    },
    agentContactProfilePictureUploadError: (state, action: PayloadAction<any>) => {
      state.agentContactProfilePictureUploadError = { error: action.payload, data: {} };
    },
    agentContactProfileInfoSucess: (state, action: PayloadAction<any>) => {
      state.agentContactProfileInfoSucess = { data: action.payload };
    },
    agentContactProfileInfoError: (state, action: PayloadAction<any>) => {
      state.agentContactProfileInfoError = { error: action.payload, data: {} };
    },
    agentContactProfilePictureDeleteSucess: (state, action: PayloadAction<any>) => {
      state.agentContactProfilePictureDeleteSucess = { data: action.payload, error: {} };
    },
    agentContactProfilePictureDeleteError: (state, action: PayloadAction<any>) => {
      state.agentContactProfilePictureDeleteError = { error: action.payload, data: {} };
    },

    agentContactsStart: (state) => {
      state.agentContacts = { data: {}, error: {}, loading: true };
    },
    agentContactsSuccess: (state, action: PayloadAction<any>) => {
      state.agentContacts = { data: action.payload, error: {}, loading: false };
    },
    agentContactsError: (state, action: PayloadAction<any>) => {
      state.agentContacts = { data: {}, error: action.payload, loading: false };
    },
    resetAgentContacts: (state) => {
      state.agentContacts = { data: {}, error: {}, loading: false };
    },
    resetAgentContactData: (state) => {
      state.agentContactVerifyOtpSuccess = { data: {}, error: {} };
      state.agentContactVerifyOtpError = { data: {}, error: {} };
      state.agentContactOTPSubmitSuccess = { data: {}, error: {} };
      state.agentContactOtpSubmitError = { error: {}, data: {} };
      state.agentContactBasicInfoEditSuccess = { data: {}, error: {} };
      state.agentContactBasicInfoEditError = { error: {}, data: {} };
      state.agentContactVerifyEmailSuccess = { data: {}, error: {} };
      state.agentContactVerifyEmailError = { data: {}, error: {} };
      state.agentContactEmailOtpSubmitError = { error: {}, data: {} };
      state.agentContactEmailOTPSubmitSuccess = { error: {}, data: {} };
    },
    resetProfilePictureData: (state) => {
      state.agentContactProfilePictureUploadSuccess = { data: {}, error: {} };
      state.agentContactProfilePictureUploadError = { data: {}, error: {} };
      state.agentContactProfilePictureDeleteError = { error: {}, data: {} };
      state.agentContactProfilePictureDeleteSucess = { data: {}, error: {} };
    },
    agentContactResetState: () => initialState,
  },
});

export const {
  agentContactSuccess,
  contactBlockSuccess,
  contactBlockError,
  agentContactError,
  agentContactFinancialInfoEditError,
  agentContactFinancialInfoEditSuccess,
  agentContactBasicInfoEditError,
  agentContactBasicInfoEditSuccess,
  agentContactPhoneOtpSuccess,
  agentContactPhoneOtpEditError,
  agentContactProfilePictureUploadSuccess,
  agentContactProfilePictureUploadError,
  agentContactOTPSubmitSuccess,
  agentContactOtpSubmitError,
  resetAgentContactData,
  agentContactProfileInfoError,
  agentContactProfileInfoSucess,
  resetProfilePictureData,
  agentContactProfilePictureDeleteSucess,
  agentContactProfilePictureDeleteError,
  agentContactEmailOtpSuccess,
  agentContactEmailOtpEditError,
  agentContactEmailOtpSubmitError,
  agentContactEmailOTPSubmitSuccess,
  agentContactResetState,
  agentContactsStart,
  agentContactsSuccess,
  agentContactsError,
  resetAgentContacts,
} = agentContactSlice.actions;
export const getContactInformation = (state: RootState) => state.agentContact;

export default agentContactSlice.reducer;
