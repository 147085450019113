import { Image } from "primereact/image";
import { ValidateResult } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { CardFieldsInterface, DATEFORMAT, THEME, ProposalTypes, CUSTOM } from "app/utils/types";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { mapObjIndexed, pickBy, isEmpty, is, pipe, trim } from "ramda";
import { formatDateFromJSDate, formatDateToSubmit } from "./luxon";
import HelloSign from "hellosign-embedded";
import { getObjectFromSessionStore, getSessionStore, removeSessionStore } from "./sessionStore";
import {
  resetgetLoanPlanProjectionState,
  resetPlanAdminSplitDollarState,
  resetPlanSplitDollarState,
  resetpostCSVDataState,
} from "app/redux";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import * as R from "ramda";
import i18n from "i18next";
import { getLocalStore } from "./localStore";
import axios from "axios";

export const IAPPLogo = (props: { classAdd?: string }) => {
  const { classAdd } = props;
  const branding = useSelector((state: RootState) => state?.contactBranding);
  const IAPPLogoAWS =
    branding.primaryLogo === "" || !branding.primaryLogo
      ? branding.theme !== THEME.DARK
        ? process.env.REACT_APP_ENVIRONMENT === "PROD"
          ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
          : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png`
        : process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`
      : branding.primaryLogo;

  return (
    <div className={`flex align-items-center my-2 gap-2 top-0 ${classAdd || "absolute"}`}>
      <div>
        <Image src={IAPPLogoAWS} alt="Image" width="180" />
      </div>
    </div>
  );
};

export const IAPPDarkLogo = (props: { classAdd?: string }) => {
  const { classAdd } = props;
  return (
    <div className={`flex align-items-center my-2 gap-2 absolute top-0 ${classAdd || "absolute"}`}>
      <div>
        <Image
          src={
            process.env.REACT_APP_ENVIRONMENT === "PROD"
              ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
              : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png"`
          }
          alt="Image"
          width="100"
          height="100"
        />
      </div>
    </div>
  );
};

export const FormBody = () => {};

export const validatePassword = (value: any): ValidateResult => {
  const hasLower = /[a-z]/.test(value);
  const hasUpper = /[A-Z]/.test(value);
  const hasNumberValue = /\d/.test(value);
  const hasSpeciaCharacter = /^(?=.*[!@=#$%^&*()_+{}\\[\]:;<>,.?~`'"|\\/\\-])[^\s]+$/.test(value);
  const hasMinimumLength = value.length >= 8;
  const returnObject = JSON.stringify({
    hasLower,
    hasUpper,
    hasSpeciaCharacter,
    hasNumberValue,
    hasMinimumLength,
  });
  return returnObject;
};
export const convertCamelCaseToSentenceCase = (camelCaseString: any) =>
  camelCaseString?.replace(/([A-Z])/g, " $1")?.replace(/^./, (str: string) => str.toUpperCase());

export function convertStringNumberToTwoDecimal(value: any, isCurrency = true, currency = "$") {
  const twoDecimalPointString = isCurrency
    ? `${currency}${Number(value).toFixed()}`
    : Number(value).toFixed(0);

  return twoDecimalPointString;
}

const capitalizeFirstLetterOfStr = (str: any) => str.charAt(0).toUpperCase() + str.slice(1);

export const convertDataToFields = (
  data: CardFieldsInterface | any,
  fieldsMapping: { [key: string]: string }[],
) => {
  const fields = fieldsMapping.map(({ key, label }: { [key: string]: string }) => ({
    id: uuidv4(),
    label: label || convertCamelCaseToSentenceCase(key),
    value: data[key],
  }));
  return fields;
};

export const capitalizeWords = R.pipe(
  R.split(" "), // Split the string into words
  R.map(capitalizeFirstLetterOfStr), // Convert each word to uppercase
  R.join(" "), // Join the words back into a string
);

export function prettifyData(data: any[]) {
  const isStringEmpty = pipe(trim, isEmpty);
  const prettyData = data?.map((item: any) => ({
    ...item,
    contactName: isStringEmpty(item.contactName) ? "-" : item.contactName,
    email: isEmpty(item?.email) ? "-" : item?.email,
  }));
  return prettyData;
}

export const capitalizeFirstLetter = (string?: string) =>
  `${string?.charAt(0).toUpperCase()}${string?.slice(1)}`;

export const formatUnits = (loanBalance: number, currency?: any) => {
  const loanBalanceData = typeof loanBalance === "string" ? Number(loanBalance) : loanBalance;
  const balance = loanBalanceData || 0;
  const roundedValue = balance?.toFixed(0);
  const formattedBalance = Number(roundedValue)?.toLocaleString();
  return currency === "$" ? `$${formattedBalance}` : formattedBalance;
};

export const getFieldFromObject = (object: any, field: any) =>
  field.split(".").reduce((obj: any, key: any) => obj?.[key], object);

export const combinedString = (messages: string[] | string) =>
  Array.isArray(messages) ? messages.map((message: any) => `${message}. `).join("\n") : messages;

export const feetToInches = (feet: number, inches: number) => feet * 12 + inches;

export const feetToCentimeters = (feet: number, inches: number) => {
  const centimeters = feet * 30.48 + inches * 2.54;
  return centimeters;
};

export const cmsToFeet = (cms: string | number) => {
  const centimeters = Number(cms);
  const inches = centimeters * 0.393701;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);
  const heightInFeet = `${feet}'${remainingInches}"`;
  return heightInFeet;
};
export function centimetersToFeetAndInches(centimeters: any) {
  // const inchesPerFoot = 12;
  const centimetersPerInch = 2.54;
  const centimetersPerFoot = 30.48;

  // Convert centimeters to feet
  const feet = Math.floor(centimeters / centimetersPerFoot);

  // Calculate the remaining centimeters after converting to feet
  const remainingCentimeters = centimeters % centimetersPerFoot;

  // Convert remaining centimeters to inches
  const inches = Math.round(remainingCentimeters / centimetersPerInch);

  return { feet, inches };
}
export const extractFilters = (data: any) => {
  const filters: any = {};
  // Loop through each column
  // eslint-disable-next-line no-restricted-syntax
  for (const column of data.column) {
    const { field, filters: columnFilters } = column;

    // Check if the column has filters
    if (columnFilters) {
      const { value } = columnFilters;

      // Add the filter value to the filters object
      if (value !== undefined && value !== "") {
        filters[field as string] = value;
      }
    }
  }

  return filters;
};
export const debounce = (callback: any, delay: number) => {
  let timeoutId: any;

  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
export function objectToQueryString(obj: any) {
  const queryParts = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = Array.isArray(obj[key][0])
        ? encodeURIComponent(JSON.stringify(obj[key][0]))
        : typeof obj[key][0] === "number"
        ? obj[key]
        : encodeURIComponent(obj[key]); // 2023-05-23,2023-05-30
      if (value && Array.isArray(obj[key][0]) && obj[key][0].length) {
        queryParts.push(`${key}=${value}`);
      } else if (value && !Array.isArray(obj[key][0])) {
        // incase on integer array
        is(Number, obj[key][0])
          ? queryParts.push(`${key}=[${value}]`)
          : queryParts.push(`${key}=["${value.toLowerCase()}"]`);
      }
    }
  }
  return queryParts.join("&");
}

export function objectToQueryStringWithLowerCase(obj: any) {
  const queryParts = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = Array.isArray(obj[key][0])
        ? encodeURIComponent(JSON.stringify(obj[key][0]))
        : typeof obj[key][0] === "number"
        ? obj[key]
        : encodeURIComponent(obj[key]); // 2023-05-23,2023-05-30
      if (value && Array.isArray(obj[key][0]) && obj[key][0].length) {
        queryParts.push(`${key}=${value}`);
      } else if (value && !Array.isArray(obj[key][0])) {
        // incase on integer array
        is(Number, obj[key][0])
          ? queryParts.push(`${key}=[${value}]`)
          : queryParts.push(`${key}=["${value}"]`);
      }
    }
  }
  return queryParts.join("&");
}

export const updateFiltersValue = (items: any, field: any, value: string | number) =>
  items.map((item: any) => {
    if (item.field === field) {
      return {
        ...item,
        filters: {
          ...item.filters,
          value,
        },
      };
    }
    return item;
  });

export const resetFiltersValue = (items: any) =>
  items.map((item: any) => ({
    ...item,
    filters: {
      ...item.filters,
      value: "",
    },
  }));

export const convertFilters = (filters: any, propName: any) =>
  mapObjIndexed((val: any[]) => [val[propName]], filters);

export const truncateString = (str: any, maxLength: any) => `${str.slice(0, maxLength)}...`;
export const removeEmptyKeys = (obj: any): object => pickBy((value) => !isEmpty(value), obj);

export function capitalizeNames(inputString: any) {
  function capitalizeFirstLetterOfArray(name: any) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  const namesArray = inputString?.split(",");
  const capitalizedNamesArray = namesArray?.map((name: any) =>
    capitalizeFirstLetterOfArray(name.trim()),
  );
  const capitalizedNamesString = capitalizedNamesArray?.join(", ");

  return capitalizedNamesString;
}

export const getFirstNameAbbreviation = (name: string) => {
  const words = name.trim().split(" ");

  const abbreviation = words
    .filter((word: string) => word.length > 0)
    .map((word: string) => word[0].toUpperCase())
    .join("");

  return abbreviation;
};

export const truncateEmailBeforeAtSymbol = (email: string) => {
  if (!email || typeof email !== "string") {
    return "";
  }

  const atIndex = email.indexOf("@");
  if (atIndex === -1 || atIndex <= 16) {
    return email;
  }

  return `${email.slice(0, 16)}...${email.slice(atIndex)}`;
};

export const getEncodedURI = (obj: any) => {
  const filterAgentsClone = { ...obj };
  if ("team" in filterAgentsClone && !isEmpty(filterAgentsClone.team[0])) {
    filterAgentsClone.team = filterAgentsClone.team.map((item: any) => Number(item));
  }
  if ("invitationDate" in filterAgentsClone && !isEmpty(filterAgentsClone.invitationDate[0])) {
    const dateVal = filterAgentsClone.invitationDate[0];
    const formattedFilterProposal = dateVal
      ? [...dateVal.map((item: string) => formatDateFromJSDate(item, "yyyy-MM-dd"))]
      : [];
    filterAgentsClone.invitationDate[0] = formattedFilterProposal;
  }
  return objectToQueryString(filterAgentsClone);
};

export const checkForAnySearchParameter = (obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => acc || obj[key].some((agent: string) => agent?.trim().length > 0),
    false,
  );

export const openHelloSignModal = (url: string, onError: () => void, onSign: () => void) => {
  const client = new HelloSign();
  const getLookUpResponse = getObjectFromSessionStore("lookUpResponse");
  // const CLIENT_ID = process.env.HELLO_SIGN_CLIENT_KEY;
  const CLIENT_ID = getLookUpResponse.helloSignClientKey;
  if (!CLIENT_ID && onError) {
    onError();
  }
  if (CLIENT_ID) {
    client.open(url, {
      clientId: CLIENT_ID,
      skipDomainVerification: true,
    });
  }
  if (onSign) {
    client.on("sign", onSign);
  }
};

// export const getOrdinal = (day) => {
//   const lastDigit = day % 10;
//   return lastDigit === 1 ? "st" : lastDigit === 2 ? "nd" : lastDigit === 3 ? "rd" : "th";
// };
export const resetCreateProposalStates = (dispatch: Dispatch<AnyAction>) => {
  removeSessionStore("fromRequestForProposal");
  removeSessionStore("proposalID");
  removeSessionStore("CompanyNameforUpload");
  removeSessionStore("prouctNameforUpload");
  dispatch(resetpostCSVDataState());
  dispatch(resetPlanAdminSplitDollarState());
  dispatch(resetPlanSplitDollarState());
  dispatch(resetgetLoanPlanProjectionState());
};

export const capitalizeFirstLetterOfEveryWordInaString = (sentence: string) =>
  sentence?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const formatFormData = ({
  formData,
  formDataFormat,
}: {
  formData: any;
  formDataFormat: any[];
}) => {
  if (!formData || !formDataFormat) return [];
  return formDataFormat
    .map((items: any) => {
      const cardApi: any = formData[items.cardType];
      if (Array.isArray(cardApi)) {
        return cardApi.map((api, i: number) => {
          const keysArray = Object.entries(api).map(([key]) => ({ key }));
          const header = {
            ...items.header,
          };
          if (header && cardApi.length > 1) {
            header.value = `${header.value} ${i + 1}`;
          }
          return {
            ...items,
            header,
            fields: convertDataToFields(api, keysArray),
          };
        });
      }
      const keysArray = Object.entries(cardApi).map(([key]) => ({ key }));
      return {
        ...items,
        fields: convertDataToFields(cardApi, keysArray),
      };
    })
    .flat();
};

export const convertCommaSeparated = (data: any) => {
  const fixingData: any = Number(data).toFixed();
  return data ? `$${Number(fixingData).toLocaleString()}` : `$ 0`;
};

export const convertCommaSeparatedWODollar = (data: any) => {
  const fixingData: any = Number(data).toFixed();
  return data ? `${Number(fixingData).toLocaleString()}` : `0`;
};

export const formatBuySellDetails = (bsData: any, proposalType: ProposalTypes) => {
  if (!bsData) return {};
  const {
    id,
    basicDetails: { entityName, state },
    planDetails: {
      businessValuation,
      currentBuySellAgreement,
      estimatedLastDate,
      fullyFundedOnDeath,
      fullyFundedOnDisability,
      lifeInsuranceType,
      annualRenewableTerm,
      meetEstimatedValuation,
      needBusinessValuation,
      note,
      updateBuySellAgreement,
    },
    shareholders,
  } = bsData;

  const formattedData = {
    id,
    basicDetails: {
      "Proposal type": proposalType,
      "Legal Name of Entity": entityName,
      state,
      "No of Shareholders": shareholders.length,
    },
    planDetails: {
      "Estimated Business Valuation": businessValuation
        ? convertCommaSeparated(businessValuation)
        : businessValuation,
      "Estimated Last Date Business Valuation Completed": estimatedLastDate
        ? formatDateToSubmit(estimatedLastDate, DATEFORMAT.TYPE3)
        : estimatedLastDate,
      "Need to complete a business valuation?": needBusinessValuation,
      "Buy Sell Agreement in place": currentBuySellAgreement,
      "Wanting to update the Buy Sell Agreement?": updateBuySellAgreement,
      "Funding in the event of death?":
        Number(fullyFundedOnDeath) === 100 ? "Full" : `${fullyFundedOnDeath}%`,
      "Want enough life insurance to meet estimated valuation in 5 years?": meetEstimatedValuation,
      "Life Insurance Utilisation": lifeInsuranceType,
      ...(annualRenewableTerm &&
        lifeInsuranceType !== "Permanent" &&
        Object.keys(annualRenewableTerm).length > 0 && {
          "Annual Renewable Term": annualRenewableTerm,
        }),
      "Fully Funded On Disability":
        fullyFundedOnDisability !== "No" ? `${fullyFundedOnDisability}` : "No",
      note,
    },
    shareholders: shareholders.map((shareholderObj: any) => {
      const {
        name,
        ownershipPercentage,
        dob,
        gender,
        nicotineTobaccoUsage,
        occupation,
        participateInActivities,
      } = shareholderObj;
      return {
        "Shareholder Name": name,
        "Date of Birth": formatDateToSubmit(dob, DATEFORMAT.TYPE3),
        Gender: gender,
        Ownership: `${ownershipPercentage}%`,
        "Nicotine or Tobacco Usage": nicotineTobaccoUsage,
        Occupation: capitalizeFirstLetter(occupation),
        "Participate In Activities": participateInActivities.join(", "),
      };
    }),
  };

  return formattedData;
};

export const getProvisionDataToPost = (data: any) => {
  if (isEmpty(data)) return [];
  const proposalId = getSessionStore("proposalID");
  const formattedData = Object.entries(data).reduce((acc: any, curr: any) => {
    const [key, val = ""] = curr;
    if (val && !isEmpty(val) && typeof val === "object") {
      const { payout, condition } = val;
      const formattedPayout = payout === CUSTOM ? data[`${key}percentage`] : payout;
      const formattedCondition = payout === CUSTOM ? data[`${key}condition`] : condition;
      acc.push({
        name: capitalizeFirstLetter(key),
        payout: Number(formattedPayout),
        condition: formattedCondition,
        proposalId,
      });
    }
    return acc;
  }, []);
  return {
    provisions: formattedData,
  };
};

export const transformString = (inputString: string, separator: string = " "): string => {
  const separatedWords = inputString.split(/(?=[A-Z])/);
  return separatedWords
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(separator);
};

export const toCamelCase = (str: string): string =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());

export const proposalStatuses = {
  requested: "Proposal Requested",
  revoked: "Proposal Revoked",
  draft: "Proposal Draft",
  created: "Proposal Created",
  received: "Advisor Received Proposal",
  sent: "Proposal Sent to Contact",
  downloaded: "Advisor Downloaded Proposal",
  feedback: "Advisor Provided Feedback",
  reviewed: "Advisor Reviewed Proposal",
  signed: "Contact Signed Proposal",
  uploaded: "Advisor Uploaded Signed Proposal",
  new_signature_requested: "Admin Requested New Signature",
  approved: "Admin Approved Proposal",
  inactive: "Inactive Proposal",
  pending_drafts: "Pending Draft",
  pending_revoked: "Pending Revoked",
};

function pad(number: any) {
  return (number < 10 ? "0" : "") + number;
}

export function getTime(timestampString: any) {
  const date = new Date(timestampString);

  // Get the UTC components
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();

  // Format the time in 24-hour and minute format
  const timeString = `${pad(utcHours)}:${pad(utcMinutes)}`;
  return timeString;
}

export function addRemoveClass(currentClassName: string, item: any, globalFontSize: string) {
  if (
    currentClassName.includes("text-xs") ||
    currentClassName.includes("text-sm") ||
    currentClassName.includes("text-base") ||
    currentClassName.includes("text-lg")
  ) {
    if (globalFontSize === "extraLarge") {
      item.classList.remove("text-xs");
      item.classList.remove("text-sm");
      item.classList.remove("text-base");
      item.classList.add("text-lg");
    } else if (globalFontSize === "large") {
      item.classList.remove("text-xs");
      item.classList.remove("text-sm");
      item.classList.remove("text-lg");
      item.classList.add("text-base");
    } else {
      item.classList.remove("text-xs");
      item.classList.remove("text-base");
      item.classList.remove("text-lg");
      item.classList.add("text-sm");
    }
  } else if (!isEmpty(globalFontSize)) {
    if (globalFontSize === "dark:text-dark-color" || globalFontSize === "text-gray-700") {
      item.classList.remove("dark:text-dark-color");
      item.classList.remove("text-gray-700");
    }

    if (
      (currentClassName.includes("dark:bg-dark-primary") &&
        globalFontSize === "light:bg-light-primary") ||
      globalFontSize === "text-gray-500"
    ) {
      item.classList.remove("dark:bg-dark-primary");
    }

    if (currentClassName.includes("text-white") && globalFontSize === "text-gray-500") {
      item.classList.remove("text-white");
    }

    if (currentClassName.includes("dark:text-white") && globalFontSize === "text-gray-700") {
      item.classList.remove("dark:text-white");
    }

    item.classList.add(globalFontSize);
  }
}

export function setThemeGlobally() {
  const globalTheme = getSessionStore("globalTheme");
  const brandingTheme = getSessionStore("brandingTheme");
  const pComponent = document.getElementsByClassName("p-card");
  const tableHead = document.querySelectorAll(".p-datatable-thead");
  const tableComponentHead = document.querySelectorAll(".p-datatable-table th");
  const tableComponentHeadRow = document.querySelectorAll(".p-datatable-table thead tr");
  const tableComponentBody = document.querySelectorAll(".p-datatable-table tbody tr");
  const tableComponentBodyData = document.querySelectorAll(
    ".p-datatable-table tbody td:not(.bg-black)",
  );
  const tableComponentPaginate = document.querySelectorAll(".p-paginator");
  const tableComponentPaginateBtn = document.querySelectorAll(".p-paginator button");
  const tableComponentPaginateActiveBtn = document.querySelectorAll(
    ".p-paginator .p-paginator-pages .p-paginator-page.p-highlight",
  );
  const ulElement = document.getElementsByTagName("ul");
  const liElement = document.getElementsByTagName("li");
  const dialogComponent = document.getElementsByClassName("p-dialog-content");
  const dialogContainer = document.getElementsByClassName("p-dialog");
  const labelElement = document.getElementsByClassName("label");
  const labelTagElement = document.getElementsByTagName("label");
  const accordionHeaderElement = document.querySelectorAll(
    ".p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link",
  );
  const accordionElement = document.getElementsByClassName("p-accordion-content");
  const sortIcon = document.querySelectorAll(".p-column-header-content .p-sortable-column-icon");
  const spanElement = document.querySelectorAll(
    "span:not(td span):not(.p-button span):not(.p-tabview-title span):not(button span):not(a span):not(.p-dropdown span):not(.p-chip-text):not(.p-chip-icon):not(.p-toast-summary):not(.p-inputgroup-addon)",
  );
  const inputGroupOn = document.querySelectorAll(".p-inputgroup-addon");

  const breadCrumb = document.getElementsByClassName("p-breadcrumb");
  const aElement = document.getElementsByTagName("a");
  const h6Element = document.getElementsByTagName("h6");
  const pElement = document.getElementsByTagName("p");
  const h4Element = document.getElementsByTagName("h4");
  const divElement = document.querySelectorAll(
    "div:not(.loginRightPanel):not(.p-sidebar-mask):not(.p-toast-message):not(.p-toast-message-text):not(.p-toast-message-content):not(.p-toast-detail):not(.p-toast-message-content div):not(.menu-div):not(.p-selectbutton .p-button)",
  );
  const tabContainerElement = document.querySelectorAll(".p-selectbutton .p-button");
  const logoElement = document.querySelectorAll(".branding img");
  const splitButton = document.querySelectorAll(".arrow-icon svg");
  const panelComp = document.getElementsByClassName("p-tabview-panels");
  const menuItemText = document.getElementsByClassName("p-menuitem-text");
  const menuItemIcon = document.getElementsByClassName("p-menuitem-icon");
  const dropDownItem = document.querySelectorAll(".p-dropdown, .p-dropdown-label");
  const dropDownVal = document.getElementsByClassName("p-dropdown-item");
  const dropDownLabel = document.getElementsByClassName("p-dropdown-label");
  const sloganInput = document.getElementById("slogan");
  const inputText = document.getElementsByClassName("p-inputtext");
  const multiSelect = document.getElementsByClassName("p-multiselect");
  const tabView = document.getElementsByClassName("p-tabview");
  const multiSelectPanel = document.querySelectorAll(".p-multiselect-panel.p-component");
  const multiSelectActivePanel = document.querySelectorAll(
    ".p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight",
  );
  const stepper = document.querySelectorAll(".p-steps-title");
  const sidebarLiUlElement = document.querySelectorAll(".p-sidebar-content li ul");
  const sidebarLiElement = document.querySelectorAll(".p-sidebar-content li");
  const multiSelectLabel = document.getElementsByClassName("p-multiselect-label");
  const tabViewHeader = document.querySelectorAll(".p-tabview-nav");
  const tabViewHeaderLink = document.querySelectorAll(".p-tabview-nav li .p-tabview-nav-link");
  const tabViewHeaderActiveTab = document.querySelectorAll(
    ".p-tabview .p-tabview-nav .p-tabview-ink-bar",
  );
  const tabViewHeaderActiveTabSpan = document.querySelectorAll(
    ".p-tabview-nav .p-tabview-selected .p-tabview-nav-link .text-layoutBlue",
  );
  const pCardHeaderIcon = document.querySelectorAll(".p-card-header .justify-content-center");
  const pChip = document.getElementsByClassName("p-chip");
  const tableChip = document.querySelectorAll("table .p-chip");
  const datePickerPanel = document.querySelectorAll(".p-datepicker:not(.p-datepicker-inline)");
  const datePickerPanelHeader = document.querySelectorAll(".p-datepicker .p-datepicker-header");
  const datePickerPanelHeaderBtn = document.querySelectorAll(
    ".p-datepicker .p-datepicker-header button",
  );
  const datePickerPanelPanelSpan = document.querySelectorAll(".p-datepicker table td span");
  const datePickerPanelPanelArrowBtn = document.querySelectorAll(
    ".p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next",
  );
  const datePickerPanelTodayBtn = document.querySelectorAll(
    ".p-datepicker table td.p-datepicker-today > span",
  );
  const datePickerPanelActiveBtn = document.querySelectorAll(
    ".p-datepicker table td > span.p-highlight",
  );
  const border1 = document.querySelectorAll(
    "div.border-1, div.border-bottom-1, .p-panel .p-panel-header, .p-tabview .p-tabview-nav, .border-2:not(.theme-container), .border-t-2, .p-inputtext",
  );
  const panelContent = document.querySelectorAll(".p-panel .p-panel-content");
  const navActive = document.querySelectorAll(".nav-active");
  const fileViewElement = document.querySelectorAll(
    ".custom-fileview, .custom-fileview div, .p-accordion-content .bg-gray-1300, .p-accordion-content .bg-gray-1300 div, .p-accordion-content .custom-tooltip-btn-proposalId, .p-accordion-content .custom-tooltip-btn-proposalId div",
  );
  const crossButton = document.querySelectorAll(".p-button.p-button-text.p-button-plain");
  const tableRightBorder = document.querySelectorAll(
    ".p-datatable .p-datatable-tbody > tr > td.p-align-right",
  );
  const pToast = document.querySelectorAll(".p-toast");
  const pDropdownPanel = document.querySelectorAll(".p-dropdown-panel, .header-split-button");
  const pDropdownPanelActiveItem = document.querySelectorAll(
    ".p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight",
  );
  const aBrandingLiElement = document.querySelectorAll(".light-branding-theme .p-menuitem");
  const aBrandingElement = document.querySelectorAll(
    ".light-branding-theme .p-menuitem-link, .light-branding-theme .p-menuitem-link span",
  );
  const tabBrandingElement = document.querySelectorAll(
    ".lightHeaderClass .p-tabview .p-tabview-nav",
  );
  const tabActiveBrandingElement = document.querySelectorAll(
    ".lightHeaderClass .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link",
  );
  const menuActiveBrandingElement = document.querySelectorAll(
    ".plans-active, .plans-active a, .plans-active a span",
  );
  const fileClickSpanElement = document.querySelectorAll(".file-click-text");

  if (!isEmpty(globalTheme)) {
    document.documentElement.classList.add(globalTheme);
  }

  if (stepper.length) {
    const steppers = Array.from(stepper);
    steppers.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(currentClassName, item, globalTheme === THEME.DARK ? "text-white" : "");
    });
  }

  if (dialogContainer.length) {
    const dialogContainers = Array.from(dialogContainer);

    dialogContainers.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(currentClassName, item, "max-w-screen-sm");
    });
  }

  if (sloganInput) {
    const currentClassName = sloganInput?.className;
    sloganInput.classList.add(
      globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
    );
    addRemoveClass(
      currentClassName,
      sloganInput,
      globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-500",
    );
    // frameZones.forEach((item: any) => {
    sloganInput.setAttribute(
      "style",
      globalTheme === THEME.DARK
        ? "color: white !important; background: rgb(7 20 38 ) !important; "
        : "",
    );
  }

  if (sortIcon.length) {
    const sortIcons = Array.from(sortIcon);
    sortIcons.forEach((item: any) => {
      item.setAttribute("style", globalTheme === THEME.DARK ? `color: white !important` : "");
    });
  }

  if (tabView.length) {
    const tabViews = Array.from(tabView);
    tabViews.forEach((item: any) => {
      item.setAttribute("style", `border: none !important;`);
    });
  }

  if (inputText.length) {
    const inputTexts = Array.from(inputText);
    inputTexts.forEach((item: any) => {
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.classList.add(globalTheme === THEME.DARK ? "dark:text-dark-color" : "!text-gray-700");
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `border: 1px solid rgba(11, 33, 63, 1) !important; background: rgba(4, 13, 25, 1) ! important; color: rgba(255, 255, 255, 1) ! important;`
          : "",
      );
    });
  }

  if (multiSelect.length) {
    const multiSelects = Array.from(multiSelect);
    multiSelects.forEach((item: any) => {
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `border: 1px solid rgba(11, 33, 63, 1) !important; background: rgba(4, 13, 25, 1) ! important;`
          : "",
      );
    });
  }

  if (multiSelectPanel.length) {
    const multiSelectPanels = Array.from(multiSelectPanel);
    multiSelectPanels.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:shadow-dark" : "shadow-light",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:overflow-hidden" : "",
      );
    });
  }

  if (datePickerPanel.length) {
    const datePickerPanels = Array.from(datePickerPanel);
    datePickerPanels.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:shadow-dark" : "shadow-light",
      );
    });
  }

  if (datePickerPanelHeader.length) {
    const datePickerPanelHeaders = Array.from(datePickerPanelHeader);
    datePickerPanelHeaders.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `border-bottom: 1px solid rgba(11, 33, 63, 1) !important;`
          : "border-bottom: 1px solid rgba(222, 226, 230, 1) !important;",
      );
    });
  }

  if (datePickerPanelTodayBtn.length) {
    const datePickerPanelTodayBtns = Array.from(datePickerPanelTodayBtn);
    datePickerPanelTodayBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-activeBtn" : "light:bg-light-activeBtn",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 1) !important;`
          : "color: rgba(73, 80, 87, 1) !important;",
      );
    });
  }

  if (datePickerPanelActiveBtn.length) {
    const datePickerPanelActiveBtns = Array.from(datePickerPanelActiveBtn);
    datePickerPanelActiveBtns.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 0.87) !important; background: rgba(4, 13, 25, 1) !important;`
          : "color: rgb(67 56 202) !important; background: rgb(238 242 255) !important;",
      );
    });
  }

  if (datePickerPanelHeaderBtn.length) {
    const datePickerPanelHeaderBtns = Array.from(datePickerPanelHeaderBtn);
    datePickerPanelHeaderBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!text-dark-color" : "",
      );
    });
  }

  if (datePickerPanelPanelSpan.length) {
    const datePickerPanelPanelSpans = Array.from(datePickerPanelPanelSpan);
    datePickerPanelPanelSpans.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK
          ? "dark:hover:!bg-dark-activeBtn"
          : "light:hover:!bg-light-activeBtn",
      );
    });
  }

  if (datePickerPanelPanelArrowBtn.length) {
    const datePickerPanelPanelArrowBtns = Array.from(datePickerPanelPanelArrowBtn);
    datePickerPanelPanelArrowBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK
          ? "dark:hover:!bg-dark-activeBtn"
          : "light:hover:!bg-light-activeBtn",
      );
    });
  }

  if (multiSelectActivePanel.length) {
    const multiSelectActivePanels = Array.from(multiSelectActivePanel);
    multiSelectActivePanels.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-hover" : "",
      );
    });
  }

  if (sidebarLiElement.length) {
    const sidebarLiElements = Array.from(sidebarLiElement);
    sidebarLiElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:hover:!bg-dark-primary" : "light:hover:bg-light-primary",
      );
    });
  }

  if (sidebarLiUlElement.length) {
    const sidebarLiUlElements = Array.from(sidebarLiUlElement);
    sidebarLiUlElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-l-blue-1100" : "light:border-l-gray-1000",
      );
    });
  }

  if (accordionElement.length) {
    const accordionElements = Array.from(accordionElement);
    accordionElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-blue-1100" : "light!border-gray-1000",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important;"
          : "",
      );
    });
  }

  if (pComponent.length) {
    const frameZones = Array.from(pComponent);
    frameZones.forEach((item: any) => {
      const currentClassName = item?.className;
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-500",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important; border: 1px #295BAC solid; overflow: hidden !important;"
          : "box-shadow: none !important; border: 1px solid #EEEEEE !important;",
      );
    });
  }

  if (dialogComponent.length) {
    const frameZones = Array.from(dialogComponent);
    frameZones.forEach((item: any) => {
      const currentClassName = item?.className;
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-500",
      );
      // frameZones.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important; "
          : "",
      );
      // });
    });
  }

  if (tableComponentHead.length) {
    const tableComponents = Array.from(tableComponentHead);
    tableComponents.forEach((item: any) => {
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK ? `background: rgb(7 20 38) !important;` : "background: white",
      );
    });
  }

  if (tableComponentHeadRow.length) {
    const tableComponentHeadRows = Array.from(tableComponentHeadRow);
    tableComponentHeadRows.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `border-bottom: 1px solid rgba(11, 33, 63, 1) !important;`
          : "border-bottom: 1px solid rgba(222, 226, 230, 1) !important;",
      );
    });
  }

  if (tableHead.length) {
    const tableComponents = Array.from(tableHead);
    tableComponents.forEach((item: any) => {
      // item.classList.add(
      //   globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      // );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK ? `background: rgb(7 20 38 ) !important` : "background: white",
      );
    });
  }

  if (panelComp.length) {
    const panelComps = Array.from(panelComp);
    panelComps.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK ? `background: rgb(7 20 38 ) !important` : "background: white",
      );
    });
  }

  if (menuItemText.length) {
    const menuItemTexts = Array.from(menuItemText);
    menuItemTexts.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: white !important; background: rgb(7 20 38 ) !important;`
          : "",
      );
    });
  }

  if (menuItemIcon.length) {
    const menuItemIcons = Array.from(menuItemIcon);
    menuItemIcons.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: white !important; background: rgb(7 20 38 ) !important;`
          : "",
      );
    });
  }

  if (dropDownItem.length) {
    const dropDownItems = Array.from(dropDownItem);
    dropDownItems.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(currentClassName, item, globalTheme === THEME.DARK ? "text-white" : "");
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: white !important; background: rgb(7 20 38 ) !important; border: 1px solid rgba(11, 33, 63, 1) !important;`
          : "",
      );
    });
  }

  if (dropDownVal.length) {
    const dropDownItems = Array.from(dropDownVal);
    dropDownItems.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: white !important; background: rgb(7 20 38 ) !important;`
          : "color: #495057 !important;",
      );
    });
  }

  if (dropDownLabel.length) {
    const dropDownLabels = Array.from(dropDownLabel);
    dropDownLabels.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-light-color",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: white !important; background: rgb(7 20 38 ) !important; border-top-right-radius: 0; border-bottom-right-radius: 0;`
          : "",
      );
    });
  }

  if (multiSelectLabel.length) {
    const multiSelectLabels = Array.from(multiSelectLabel);
    multiSelectLabels.forEach((item: any) => {
      item.setAttribute("style", globalTheme === THEME.DARK ? `color: white !important;` : "");
    });
  }

  if (tableComponentBody.length) {
    const tableComponents = Array.from(tableComponentBody);
    tableComponents.forEach((item: any) => {
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important; "
          : "",
      );
    });
  }

  if (tableComponentBodyData.length) {
    const tableComponents = Array.from(tableComponentBodyData);
    tableComponents.forEach((item: any) => {
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important; border-bottom: 1px solid rgba(11, 33, 63, 1) !important;"
          : "",
      );
    });
  }

  if (tableComponentPaginateBtn.length) {
    const tableComponents = Array.from(tableComponentPaginateBtn);
    tableComponents.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK
          ? "dark:hover:!bg-dark-activeBtn"
          : "light:hover:!bg-light-activeBtn",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:hover:!text-dark-color" : "hover:text-gray-700",
      );
    });
  }

  if (tableComponentPaginateActiveBtn.length) {
    const tableComponentPaginateActiveBtns = Array.from(tableComponentPaginateActiveBtn);
    tableComponentPaginateActiveBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!text-dark-color" : "!text-color-stepper",
      );
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:!bg-dark-activeBtn" : "light:!bg-light-activeBtn",
      );
    });
  }

  if (pElement.length) {
    const pElements = Array.from(pElement);
    pElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
    });
  }

  if (labelElement.length) {
    const labelElements = Array.from(labelElement);
    labelElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
    });
  }

  if (labelTagElement.length) {
    const labelElements = Array.from(labelTagElement);
    labelElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
    });
  }

  if (tableComponentPaginate.length) {
    const tableComponents = Array.from(tableComponentPaginate);
    tableComponents.forEach((item: any) => {
      item.classList.add(
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );

      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 / var(--tw-bg-opacity) !important; "
          : "",
      );
    });
  }

  if (logoElement.length) {
    const activeBrandingUser = getObjectFromSessionStore("activeBrandingUser");
    if (activeBrandingUser.activeUserType === "iapp") {
      const logoElements = Array.from(logoElement);
      logoElements.forEach((item: any) => {
        item.src =
          globalTheme === THEME.DARK
            ? process.env.REACT_APP_ENVIRONMENT === "PROD"
              ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
              : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png`
            : process.env.REACT_APP_ENVIRONMENT === "PROD"
            ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
            : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`;
      });
    }
  }

  if (ulElement.length) {
    const ulElements = Array.from(ulElement);
    ulElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-500",
      );

      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important; "
          : "",
      );
    });
  }

  if (liElement.length) {
    const liElements = Array.from(liElement);
    liElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "text-white" : "text-gray-500",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:hover:!bg-dark-hover" : "light:hover:bg-light-primary",
      );
    });
  }

  if (breadCrumb.length) {
    const breadCrumbs = Array.from(breadCrumb);
    breadCrumbs.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "text-white" : "text-gray-500",
      );
    });
  }

  if (h6Element.length) {
    const h6Elements = Array.from(h6Element);
    h6Elements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-500",
      );
    });
  }

  if (h4Element.length) {
    const h4Elements = Array.from(h4Element);
    h4Elements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
    });
  }

  if (aElement.length) {
    const aElements = Array.from(aElement);
    aElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; background: rgb(7 20 38 ) !important; "
          : "",
      );
    });
  }

  if (aBrandingLiElement.length) {
    const aBrandingLiElements = Array.from(aBrandingLiElement);
    aBrandingLiElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        brandingTheme === "b-dark" ? "hover:!bg-dark-primary" : "hover:!bg-light-primary",
      );
      item.setAttribute(
        "style",
        brandingTheme === "b-light" ? "background: rgb(255 255 255 ) !important; " : "",
      );
    });
  }

  if (aBrandingElement.length) {
    const aBrandingElements = Array.from(aBrandingElement);
    aBrandingElements.forEach((item: any) => {
      item.setAttribute(
        "style",
        brandingTheme === "b-light"
          ? "color: rgba(73, 80, 87, 1) !important; background: white !important; "
          : "",
      );
    });
  }

  if (tabBrandingElement.length) {
    const tabBrandingElements = Array.from(tabBrandingElement);
    tabBrandingElements.forEach((item: any) => {
      item.setAttribute(
        "style",
        brandingTheme === "b-light"
          ? "color: rgba(73, 80, 87, 1) !important; border-color: #dee2e6 !important"
          : "",
      );
    });
  }

  if (tabActiveBrandingElement.length) {
    const tabActiveBrandingElements = Array.from(tabActiveBrandingElement);
    tabActiveBrandingElements.forEach((item: any) => {
      item.setAttribute(
        "style",
        brandingTheme === "b-light"
          ? "color: #1581ED !important; background: transparent !important; "
          : "",
      );
    });
  }

  if (menuActiveBrandingElement.length) {
    const menuActiveBrandingElements = Array.from(menuActiveBrandingElement);
    menuActiveBrandingElements.forEach((item: any) => {
      item.setAttribute("style", "color: #ffffff !important; background: #1581ED !important;");
    });
  }

  if (spanElement.length) {
    const spanElements = Array.from(spanElement);
    spanElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
      item.setAttribute("style", globalTheme === THEME.DARK ? "color: white !important; " : "");
    });
  }

  if (fileClickSpanElement.length) {
    const fileClickSpanElements = Array.from(fileClickSpanElement);
    fileClickSpanElements.forEach((item: any) => {
      item.setAttribute("style", "color: #1581ED !important;");
    });
  }

  if (inputGroupOn.length) {
    const inputGroupOns = Array.from(inputGroupOn);
    inputGroupOns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: white !important; border: 1px solid rgba(11, 33, 63, 1) !important;"
          : "",
      );
    });
  }

  if (divElement.length) {
    const divElements = Array.from(divElement);
    divElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "text-gray-700",
      );
    });
  }

  if (tabContainerElement.length) {
    const divElements = Array.from(tabContainerElement);
    divElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:bg-dark-primary" : "light:bg-light-primary",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:text-dark-color" : "",
      );
    });
  }

  if (splitButton.length) {
    const splitButtons = Array.from(splitButton);
    splitButtons.forEach((item: any) => {
      item.setAttribute("style", globalTheme === THEME.DARK ? "text-white" : "text-gray-700");
    });
  }

  if (sidebarLiUlElement.length) {
    const sidebarLiUlElements = Array.from(sidebarLiUlElement);
    sidebarLiUlElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-l-blue-1100" : "light:border-l-gray-1000",
      );
    });
  }

  if (sidebarLiElement.length) {
    const sidebarLiElements = Array.from(sidebarLiElement);
    sidebarLiElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:hover:!bg-dark-primary" : "light:hover:bg-light-primary",
      );
    });
  }
  if (tabViewHeader.length) {
    const tabViewHeaders = Array.from(tabViewHeader);
    tabViewHeaders.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-dark-borderColor" : "",
      );
    });
  }

  if (tabViewHeaderLink.length) {
    const tabViewHeaderLinks = Array.from(tabViewHeaderLink);
    tabViewHeaderLinks.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-dark-borderColor" : "",
      );
    });
  }

  if (tabViewHeaderActiveTab.length) {
    const tabViewHeaderActiveTabs = Array.from(tabViewHeaderActiveTab);
    tabViewHeaderActiveTabs.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-btn" : "!bg-layoutBlue",
      );
    });
  }

  if (tabViewHeaderActiveTabSpan.length) {
    const tabViewHeaderActiveTabSpans = Array.from(tabViewHeaderActiveTabSpan);
    tabViewHeaderActiveTabSpans.forEach((item: any) => {
      item.classList.add(globalTheme === THEME.DARK ? "dark:!text-blue-1200" : "!text-layoutBlue");
    });
  }

  if (pCardHeaderIcon.length) {
    const pCardHeaderIcons = Array.from(pCardHeaderIcon);
    pCardHeaderIcons.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: #93C5FD !important; background: #183462 !important; border-width: 2px !important; border-color: #295BAC !important;"
          : "",
      );
    });
  }

  if (pChip.length) {
    const pChips = Array.from(pChip);
    pChips.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: #ffffff !important; background: #040D19 !important; border-width: 1px !important; border-color: #1D2530 !important;"
          : "",
      );
    });
  }

  if (tableChip.length) {
    const tableChips = Array.from(tableChip);
    tableChips.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? "color: #CDCFD1 !important; background: #363D47 !important; border-width: 1px !important; border-color: #CDCFD1 !important;"
          : "",
      );
    });
  }

  if (datePickerPanel.length) {
    const datePickerPanels = Array.from(datePickerPanel);
    datePickerPanels.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:shadow-dark" : "shadow-light",
      );
    });
  }

  if (datePickerPanelHeader.length) {
    const datePickerPanelHeaders = Array.from(datePickerPanelHeader);
    datePickerPanelHeaders.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-primary" : "light:bg-light-primary",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `border-bottom: 1px solid rgba(11, 33, 63, 1) !important;`
          : "border-bottom: 1px solid rgba(222, 226, 230, 1) !important;",
      );
    });
  }

  if (datePickerPanelHeaderBtn.length) {
    const datePickerPanelHeaderBtns = Array.from(datePickerPanelHeaderBtn);
    datePickerPanelHeaderBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!text-dark-color" : "",
      );
    });
  }

  if (datePickerPanelPanelSpan.length) {
    const datePickerPanelPanelSpans = Array.from(datePickerPanelPanelSpan);
    datePickerPanelPanelSpans.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK
          ? "dark:hover:!bg-dark-activeBtn"
          : "light:hover:!bg-light-activeBtn",
      );
    });
  }

  if (datePickerPanelPanelArrowBtn.length) {
    const datePickerPanelPanelArrowBtns = Array.from(datePickerPanelPanelArrowBtn);
    datePickerPanelPanelArrowBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK
          ? "dark:hover:!bg-dark-activeBtn"
          : "light:hover:!bg-light-activeBtn",
      );
    });
  }

  if (datePickerPanelTodayBtn.length) {
    const datePickerPanelTodayBtns = Array.from(datePickerPanelTodayBtn);
    datePickerPanelTodayBtns.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-activeBtn" : "light:bg-light-activeBtn",
      );
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 1) !important;`
          : "color: rgba(73, 80, 87, 1) !important;",
      );
    });
  }

  if (datePickerPanelActiveBtn.length) {
    const datePickerPanelActiveBtns = Array.from(datePickerPanelActiveBtn);
    datePickerPanelActiveBtns.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 0.87) !important; background: rgba(4, 13, 25, 1) !important;`
          : "color: rgb(67 56 202) !important; background: rgb(238 242 255) !important;",
      );
    });
  }

  if (border1.length) {
    const border1s = Array.from(border1);
    border1s.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-blue-1100" : "light:!border-gray-1000",
      );
    });
  }

  if (panelContent.length) {
    const panelContents = Array.from(panelContent);
    panelContents.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 0.87) !important; background: #071426 !important; border-color: #0B213F !important;`
          : "",
      );
    });
  }

  if (navActive.length) {
    const navActives = Array.from(navActive);
    navActives.forEach((item: any) => {
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 1) !important; background: rgba(21, 129, 237, 1) !important;`
          : "",
      );
    });
  }

  if (accordionHeaderElement.length) {
    const accordionHeaderElements = Array.from(accordionHeaderElement);
    accordionHeaderElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-blue-1100" : "light:!border-gray-1000",
      );
    });
  }

  if (fileViewElement.length) {
    const fileViewElements = Array.from(fileViewElement);
    fileViewElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-blue-1600" : "light:!bg-gray-light-shade",
      );
    });
  }

  if (crossButton.length) {
    const crossButtons = Array.from(crossButton);
    crossButtons.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!text-dark-color" : "light:!text-light-color",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:hover:!bg-dark-hover" : "light:!bg-selected-column-bg",
      );
    });
  }

  if (tableRightBorder.length) {
    const tableRightBorders = Array.from(tableRightBorder);
    tableRightBorders.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!border-blue-1100" : "light:!border-gray-1000",
      );
    });
  }

  if (pToast.length) {
    const pToasts = Array.from(pToast);
    pToasts.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!z-999999" : "!z-999999",
      );
    });
  }

  if (pDropdownPanel.length) {
    const pDropdownPanels = Array.from(pDropdownPanel);
    pDropdownPanels.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!shadow-dark" : "!shadow-light",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:overflow-hidden" : "",
      );
    });
  }

  if (pDropdownPanelActiveItem.length) {
    const pDropdownPanelActiveItems = Array.from(pDropdownPanelActiveItem);
    pDropdownPanelActiveItems.forEach((item: any) => {
      const currentClassName = item?.className;
      item.setAttribute(
        "style",
        globalTheme === THEME.DARK
          ? `color: rgba(255, 255, 255, 1) !important;`
          : "color: #4338CA!important;",
      );
      addRemoveClass(
        currentClassName,
        item,
        globalTheme === THEME.DARK ? "dark:!bg-dark-hover" : "light:!bg-selectedTableHeadBg",
      );
    });
  }
}

export function setDropDownFontsTheme() {
  const globalFontSize = getSessionStore("globalFontSize");
  const pComponent = document.querySelectorAll("p-component:not(.p-tag)");
  const dropDownElement = document.getElementsByClassName("p-dropdown");
  const dropDownItem = document.getElementsByClassName("p-dropdown-items");

  if (pComponent.length || dropDownElement.length || dropDownItem.length) {
    const frameZones = Array.from(pComponent);
    const dropDowns = Array.from(dropDownElement);
    const dropDownItems = Array.from(dropDownItem);
    let fontSize = "16px";
    if (globalFontSize === "extraLarge") {
      fontSize = "24px";
    } else if (globalFontSize === "large") {
      fontSize = "20px";
    }
    frameZones?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}`);
    });
    dropDowns?.forEach((item: any) => {
      const labels = item.getElementsByClassName("p-dropdown-label");
      if (labels.length) {
        const dropDownLabels = Array.from(labels);
        dropDownLabels?.forEach((labelSpan: any) => {
          labelSpan.setAttribute("style", `font-size: ${fontSize} !important`);
        });
      }
    });
    dropDownItems?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}`);
    });
  }
}

export function setFontSizeGlobally() {
  const globalFontSize = getSessionStore("globalFontSize");
  const pComponent = document.querySelectorAll("p-component:not(.p-tag)");
  const spanElement = document.getElementsByTagName("span");
  const tagElement = document.querySelectorAll(".p-tag-value, .p-tag");
  const h6Element = document.getElementsByTagName("h6");
  const h4Element = document.querySelectorAll("h4, h5");
  const divElement = document.getElementsByTagName("div");
  const headerText = document.getElementsByClassName("headerText");
  const headerIcon = document.getElementsByClassName("headerIcon");
  const dropDownElement = document.getElementsByClassName("p-dropdown");
  const dialogElement = document.querySelectorAll(".p-dialog-content, .p-dialog-title");
  const dropDownItem = document.getElementsByClassName("p-dropdown-items");
  const buttons = document.getElementsByClassName("p-button");
  const inputGroup = document.getElementsByClassName("p-inputgroup");
  const inputText = document.getElementsByClassName("p-inputtext");
  const tableComponentPaginateBtn = document.querySelectorAll(".p-paginator button");

  if (pComponent.length || dropDownElement.length || buttons.length || dropDownItem.length) {
    const frameZones = Array.from(pComponent);
    const dropDowns = Array.from(dropDownElement);
    const dropDownItems = Array.from(dropDownItem);
    const inputGroups = Array.from(inputGroup);
    const inputTexts = Array.from(inputText);
    const button = Array.from(buttons);
    let fontSize = "16px";
    if (globalFontSize === "extraLarge") {
      fontSize = "24px";
    } else if (globalFontSize === "large") {
      fontSize = "20px";
    }
    frameZones?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}`);
    });
    dropDowns?.forEach((item: any) => {
      const labels = item.getElementsByClassName("p-dropdown-label");
      if (labels.length) {
        const dropDownLabels = Array.from(labels);
        dropDownLabels?.forEach((labelSpan: any) => {
          labelSpan.setAttribute("style", `font-size: ${fontSize}`);
        });
      }
    });
    dropDownItems?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}`);
    });
    inputGroups?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}`);
    });
    inputTexts?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}`);
    });
    button?.forEach((item: any) => {
      const labels = item.getElementsByClassName("p-button-label");
      if (labels.length) {
        const dropDownLabels = Array.from(labels);
        dropDownLabels?.forEach((labelSpan: any) => {
          labelSpan.setAttribute("style", `font-size: ${fontSize}`);
        });
      }
    });
  }

  if (tagElement.length) {
    const frameZones = Array.from(tagElement);
    let fontSize = "14px";
    if (globalFontSize === "extraLarge") {
      fontSize = "18px";
    } else if (globalFontSize === "large") {
      fontSize = "16px";
    }

    frameZones.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize}; padding: 4px !important;`);
    });
  }

  if (tableComponentPaginateBtn.length) {
    const tableComponentPaginateBtns = Array.from(tableComponentPaginateBtn);
    let fontSize = "14px";
    if (globalFontSize === "extraLarge") {
      fontSize = "24px";
    } else if (globalFontSize === "large") {
      fontSize = "20px";
    }

    tableComponentPaginateBtns.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize};`);
    });
  }

  if (dialogElement.length) {
    const frameZones = Array.from(dialogElement);
    let fontSize = "14px";
    if (globalFontSize === "extraLarge") {
      fontSize = "24px";
    } else if (globalFontSize === "large") {
      fontSize = "20px";
    }

    frameZones.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize};`);
    });
  }

  if (dialogElement.length) {
    const frameZones = Array.from(dialogElement);
    let fontSize = "14px";
    if (globalFontSize === "extraLarge") {
      fontSize = "24px";
    } else if (globalFontSize === "large") {
      fontSize = "20px";
    }

    frameZones.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${fontSize};`);
    });
  }

  if (headerText || headerIcon) {
    const headerIcons = Array.from(headerIcon);
    const headerTexts = Array.from(headerText);
    let textFont = "24px";
    if (globalFontSize === "large") {
      textFont = "30px";
    } else if (globalFontSize === "extraLarge") {
      textFont = "36px";
    }
    headerTexts?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${textFont}`);
    });
    headerIcons?.forEach((item: any) => {
      item.setAttribute("style", `font-size: ${textFont}`);
    });
  }

  if (spanElement.length) {
    const spanElements = Array.from(spanElement);
    spanElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(currentClassName, item, globalFontSize);
    });
  }
  if (h6Element.length) {
    const h6Elements = Array.from(h6Element);
    h6Elements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(currentClassName, item, globalFontSize);
    });
  }
  if (h4Element.length) {
    const h4Elements = Array.from(h4Element);
    h4Elements.forEach((item: any) => {
      const currentClassName = item?.className;
      if (
        currentClassName.includes("text-xl") ||
        currentClassName.includes("text-2xl") ||
        currentClassName.includes("text-3xl") ||
        currentClassName.includes("text-lg")
      ) {
        if (globalFontSize === "extraLarge") {
          item.classList.remove("text-xl");
          item.classList.remove("text-2xl");
          item.classList.add("text-3xl");
        } else if (globalFontSize === "large") {
          item.classList.remove("text-3xl");
          item.classList.remove("text-xl");
          item.classList.add("text-2xl");
        } else {
          item.classList.remove("text-3xl");
          item.classList.remove("text-2xl");
          item.classList.add("text-xl");
        }
      }
    });
  }

  if (divElement.length) {
    const divElements = Array.from(divElement);
    divElements.forEach((item: any) => {
      const currentClassName = item?.className;
      addRemoveClass(currentClassName, item, globalFontSize);
    });
  }
}

export function ValidatePhoneNumber(value: any) {
  const lastTenDigits = String(value).substring(value.length - 10);
  if (lastTenDigits === "" || lastTenDigits.trim() === "+") return i18n.t("general.phoneRequired");
  if (!/^\d+$/.test(lastTenDigits)) return i18n.t("general.phoneValidation.message");
  return true;
}

export const getAccessForPermission = (
  resourceName: any,
  accessType: "readAccess" | "writeAccess" = "writeAccess",
) => {
  const superAdminUser = getLocalStore("useraccess");
  if (superAdminUser?.isSuperAdmin) return false;
  const getlookUpResponse = getObjectFromSessionStore("lookUpResponse");
  const roleData = getlookUpResponse?.permissionData
    ?.find((item: any) => item.roleName === getlookUpResponse?.role?.roleName)
    ?.permissions?.find((permissions: any) => permissions?.resource?.name === resourceName);
  if (roleData) {
    return !roleData[accessType];
  }
  return false;
};

export const getFileName = (path: string) => {
  const idx = path.lastIndexOf("/");
  const fileName = path.substring(idx + 1);
  const jdx = fileName?.lastIndexOf("_");
  const ext = fileName?.split(".")?.pop();
  return fileName.substring(0, jdx) + (ext ? `.${ext}` : "");
};

export function convertToCSV(objArray: any) {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  // Add headers

  const headers = Object.keys(array[0]);
  // eslint-disable-next-line
  str += headers.join(",") + "\r\n";

  // Add data
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let j = 0; j < headers.length; j++) {
      if (line !== "") line += ",";

      // line += array[i][headers[j]];
      line += `"${array[i][headers[j]]}"`;
    }
    // eslint-disable-next-line
    str += line + "\r\n";
  }

  return str;
}

export function downloadCSV(data: any, filename: any) {
  const csv = convertToCSV(data);

  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const CHAT_API_URL = "https://zealert-api.westus2.cloudapp.azure.com/core/v1";

export const getChatConversation = (currUserId: string, receiverUserId: string) =>
  axios.post(`${CHAT_API_URL}/workspace/conversation`, {
    conversation: {
      title: "",
      created_by: currUserId,
    },
    participants: [
      {
        participant_id: currUserId,
      },
      {
        participant_id: receiverUserId,
      },
    ],
  });

export const sendMessage = (
  conversationId: number,
  currUserId: string,
  message?: string,
  files?: any[],
) =>
  axios.post(`${CHAT_API_URL}/messages`, {
    conversation_id: conversationId,
    sender: currUserId,
    message: message ?? "",
    data: {
      files: files ?? [],
    },
  });

export const WAIVE_OFF_DAY_COUNT = 0; // set to 4 once qa testing is done
