import { makeRequest } from "app/utils/makeRequest";
import { getSessionStore, setSessionStore } from "app/utils/sessionStore";
import { THEME } from "app/utils/types";
import { CHAT_API_URL } from "app/utils/utils";
import { CopilotWidget, Root } from "neio-copilot";
import { FC } from "react";
import { useLocation } from "react-router-dom";

interface IChat {
  user: any;
  peers: any[];
  children?: any;
}

const Chat: FC<IChat> = ({ peers, user, children }) => {
  const downloadAndSaveFile = (url: string, name?: string) =>
    fetch(url, { method: "get" })
      .then((response) => response.blob())
      .then((response) => {
        const href = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", name || "doc");
        document.body.appendChild(link);
        link.click();
      });

  const uploadFile = (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
    const formData = new FormData();
    formData.append("file", file);

    return makeRequest(
      "chatFileUpload",
      {
        "Content-Type": "multipart/form-data",
      },
      {
        onUploadProgress,
      },
    )
      .post(formData)
      .then((res) => res.data.Key)
      .catch((err) => {
        if (err.message?.includes("image\\/png|image\\/jpeg|image\\/jpg|application\\/pdf")) {
          throw new Error("Please try uploading PDF/Word document/PNG/ JPG/JPEG files.");
        }
        throw new Error(err?.message ?? err);
      });
  };

  const deleteFile = (fileId: string) =>
    makeRequest("chatFileRemove").delete(encodeURIComponent(fileId), true) as any;

  const downloadFile = (fileId: string) =>
    makeRequest("chatFile")
      .get(encodeURIComponent(fileId), true)
      .then((res) => {
        res.data.data &&
          downloadAndSaveFile(res.data.data, fileId.substring(fileId.lastIndexOf("/") + 1));
      });

  const getPresignedUrl = (fileId: any) =>
    makeRequest("chatFile")
      .get(encodeURIComponent(fileId), true)
      .then((res) => res.data.data);

  const { pathname } = useLocation();

  const onMessageReceived = () => {
    const chatIcon = document.querySelector<HTMLSpanElement>(".chat-icon");

    if (chatIcon) {
      setSessionStore("showChatDot", true);
      chatIcon.classList.remove("hidden");
    }
  };

  const globalTheme = getSessionStore("globalTheme");

  return (
    <Root
      options={{
        apiKey: "",
        apiUrl: CHAT_API_URL,
        user,
        peers,
        fileOptions: {
          downloadOption: "direct",
          uploadFile,
          deleteFile,
          downloadFile,
          getPresignedUrl,
        },
        headless: pathname !== "/chat",
        onMessageReceived,
        features: {
          chatWithUsers: true,
          chatWithBot: false,
        },
        theme: {
          defaultTheme: {},
          customTheme:
            globalTheme === THEME.DARK
              ? {
                  chatWindow: {
                    color: { backgroundColor: "rgb(7, 20, 38)" },
                  },
                  peopleSearchBox: {
                    color: {
                      borderColor: "rgba(11, 33, 63, 1)",
                      textColor: "#000",
                      searchButtonColorBg: "#1581ED",
                      seachButtonBorder: "#1581ED",
                    },
                  },
                  listOfPeopleForChat: {
                    fontColor: {
                      noResultsFoundTextColor: "#fff",
                    },
                  },
                  searchedList: {
                    color: {
                      searchedListBg: "#FFff00",
                      unreadMessagesBg: "#FF00ff",
                    },
                    fontColor: {
                      personNameTextColor: "#fff",
                      lastMessageTextColor: "#fff",
                    },
                  },
                  profileDisplay: {
                    color: {
                      titleBg: "rgb(7, 20, 38)",
                      backgroundColor: "rgba(11, 33, 63, 1)",
                    },
                    fontColor: {
                      titleNameColor: "#ff11ff",
                      titleColor: "#fff",
                    },
                  },
                  blockModal: {
                    color: {
                      backgroundColor: "rgb(7, 20, 38)",
                      actionButtonBgColor: "#e24c4c",
                      cancelButtonBorder: "#71717A",
                      blockMenuBgColor: "#fff",
                    },
                    fontColor: {
                      modalTitleColor: "#fff",
                      modalSubtitleColor: "#fff",
                      cancelTextColor: "#fff",
                      actionButtonTextColor: "#fff",
                    },
                  },
                  AddNewChatModalTheme: {
                    color: {
                      backgroundColor: "rgb(7, 20, 38)",
                      cancelIconColor: "#71717A",
                      headerBorder: "rgba(11, 33, 63, 1)",
                    },
                    fontColor: {
                      titleTextColor: "#fff",
                      subTitleTextColor: "#fff",
                      inputTextColor: "#fff",
                    },
                  },
                  searchedChat: {
                    color: {
                      chevronColor: "#71717A",
                      searchedBorderColor: "#ff0000",
                    },
                    fontColor: {
                      textColor: "#fff",
                      emailColor: "#fff",
                      noMatchesFoundText: "#fff",
                    },
                  },
                  fileLimitModal: {
                    color: {
                      backgroundColor: "#FFFFFF",
                      buttonBgColor: "#1581ED",
                      buttonBorderColor: "#3B82F6",
                    },
                    fontColor: {
                      contentTextColor: "#6B7280",
                      buttonLabelColor: "#FFFFFF",
                    },
                  },
                  textArea: {
                    color: {
                      backgroundColor: "rgba(11, 33, 63, 1)",
                    },
                    // fontColor: {},
                  },
                  peopleSearchBarChat: {
                    fontColor: {
                      searchTextColor: "#fff",
                    },
                    color: {
                      backgroundColor: "rgba(11, 33, 63, 1)",
                    },
                  },
                }
              : {
                  blockModal: {
                    color: {
                      actionButtonBgColor: "#e24c4c",
                    },
                  },
                },
        },
      }}
    >
      <CopilotWidget />
      {children}
    </Root>
  );
};

export default Chat;
