import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_PENDING_PROPOSALS = "GET_PENDING_PROPOSALS";
export const GET_INPROGRESS_PENDING_PROPOSALS = "GET_INPROGRESS_PENDING_PROPOSALS";
export const GET_BASIC_INFO_PENDING_PROPOSALS = "GET_BASIC_INFO_PENDING_PROPOSALS";
export const GET_INSURANCE_UNDERWRITING_PENDING_PROPOSALS =
  "GET_INSURANCE_UNDERWRITING_PENDING_PROPOSALS";
export const GET_BUY_SELL_BUSINESS_VALUATION_PENDING_PROPOSALS =
  "GET_BUY_SELL_BUSINESS_VALUATION_PENDING_PROPOSALS";
export const SAVE_BASIC_INFO_PENDING_PROPOSALS = "SAVE_BASIC_INFO_PENDING_PROPOSALS";
export const VERIFY_DOCUMENT = "VERIFY_DOCUMENT";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_PLAN_FINALIZE_PENDING_PROPOSALS = "GET_PLAN_FINALIZE_PENDING_PROPOSALS";
export const DELETE_ADDITION_REQ_TASK = "DELETE_ADDITION_REQ_TASK";
export const DELETE_BASIC_INFO_INVOICE = "DELETE_BASIC_INFO_INVOICE";
export const GENERATE_BASIC_INFO_INVOICE = "GENERATE_BASIC_INFO_INVOICE";
interface PendingResponseObject {
  data?: any;
  totalContent: number;
  state: boolean;
}

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

export interface ProgressStateInterface {
  basicDetails: number;
  planFinalization: number;
  underwriting: number;
}

export interface ProgressBuySellStateInterface {
  basicDetails: number;
  businessValuation: number;
  planFinalization: number;
}

interface pendingProposalResponseType {
  fetchPendingProposalsSuccess?: PendingResponseObject | null;
  fetchPendingProposalsError?: object;
  fetchPendingProposalsLoading?: boolean;
  fetchPendingInProgressProposalsSuccess?: PendingResponseObject | null;
  fetchPendingInProgressProposalsError?: object;
  fetchPendingInProgressProposalsLoading?: boolean;
  basicInfoPendingProposals: InitialStateInterface;
  generateBasicInfoInvoice: InitialStateInterface;
  saveBasicInfoPendingProposals: InitialStateInterface;
  verifyDocPendingProposals: InitialStateInterface;
  getDocPendingProposals: InitialStateInterface;
  overallProgress: ProgressStateInterface;
  insuranceUnderwritingPendingProposals: InitialStateInterface;
  planFinalizePendingProposals: InitialStateInterface;
  businessValuationPendingProposals: InitialStateInterface;
  deleteAdditionalTask: InitialStateInterface;
  deleteInvoice: InitialStateInterface;
}

const initialState: pendingProposalResponseType = {
  fetchPendingProposalsSuccess: null,
  fetchPendingProposalsError: {},
  fetchPendingProposalsLoading: false,
  fetchPendingInProgressProposalsSuccess: null,
  fetchPendingInProgressProposalsError: {},
  fetchPendingInProgressProposalsLoading: false,
  basicInfoPendingProposals: { data: {}, error: {}, loading: false },
  generateBasicInfoInvoice: { data: {}, error: {}, loading: false },
  insuranceUnderwritingPendingProposals: { data: {}, error: {}, loading: false },
  planFinalizePendingProposals: { data: {}, error: {}, loading: false },
  businessValuationPendingProposals: { data: {}, error: {}, loading: false },
  saveBasicInfoPendingProposals: { data: {}, error: {}, loading: false },
  verifyDocPendingProposals: { data: {}, error: {}, loading: false },
  getDocPendingProposals: { data: {}, error: {}, loading: false },
  deleteAdditionalTask: { data: {}, error: {}, loading: false },
  deleteInvoice: { data: {}, error: {}, loading: false },
  overallProgress: { basicDetails: 0, planFinalization: 0, underwriting: 0 },
};

export const pendingProposalSlice = createSlice({
  name: "pendingProposals",
  initialState,
  reducers: {
    fetchPendingProposalsOngoing: (state) => {
      state.fetchPendingProposalsSuccess = null;
      state.fetchPendingProposalsError = {};
      state.fetchPendingProposalsLoading = true;
    },
    fetchPendingProposalsSuccess: (state, action: PayloadAction<PendingResponseObject>) => {
      state.fetchPendingProposalsSuccess = action.payload;
      state.fetchPendingProposalsError = {};
      state.fetchPendingProposalsLoading = false;
    },
    fetchPendingProposalsError: (state, action: PayloadAction<PendingResponseObject>) => {
      state.fetchPendingProposalsSuccess = null;
      state.fetchPendingProposalsError = action.payload;
      state.fetchPendingProposalsLoading = false;
    },
    fetchPendingInProgressProposalsOngoing: (state) => {
      state.fetchPendingInProgressProposalsSuccess = null;
      state.fetchPendingInProgressProposalsError = {};
      state.fetchPendingInProgressProposalsLoading = true;
    },
    fetchPendingInProgressProposalsSuccess: (
      state,
      action: PayloadAction<PendingResponseObject>,
    ) => {
      state.fetchPendingInProgressProposalsSuccess = action.payload;
      state.fetchPendingInProgressProposalsError = {};
      state.fetchPendingInProgressProposalsLoading = false;
    },
    fetchPendingInProgressProposalsError: (state, action: PayloadAction<PendingResponseObject>) => {
      state.fetchPendingInProgressProposalsSuccess = null;
      state.fetchPendingInProgressProposalsError = action.payload;
      state.fetchPendingInProgressProposalsLoading = false;
    },
    fetchBasicInfoStart: (state) => {
      state.basicInfoPendingProposals = { data: {}, error: {}, loading: true };
    },
    fetchBasicInfoSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.basicInfoPendingProposals = { data: action.payload, error: {}, loading: false };
    },
    fetchBasicInfoError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.basicInfoPendingProposals = { data: {}, error: action.payload, loading: false };
    },
    updateOverallProgress: (state, action: PayloadAction<any>) => {
      state.overallProgress = action.payload;
    },
    fetchInsuranceUnderwritingStart: (state) => {
      state.insuranceUnderwritingPendingProposals = { data: {}, error: {}, loading: true };
    },
    fetchInsuranceUnderwritingSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.insuranceUnderwritingPendingProposals = {
        data: action.payload,
        error: {},
        loading: false,
      };
    },
    fetchInsuranceUnderwritingError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.insuranceUnderwritingPendingProposals = {
        data: {},
        error: action.payload,
        loading: false,
      };
    },
    fetchPlanFinalizationStart: (state) => {
      state.planFinalizePendingProposals = { data: {}, error: {}, loading: true };
    },
    fetchPlanFinalizationSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.planFinalizePendingProposals = {
        data: action.payload,
        error: {},
        loading: false,
      };
    },
    fetchPlanFinalizationError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.planFinalizePendingProposals = {
        data: {},
        error: action.payload,
        loading: false,
      };
    },
    fetchBusinessValuationStart: (state) => {
      state.businessValuationPendingProposals = { data: {}, error: {}, loading: true };
    },
    fetchBusinessValuationSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.businessValuationPendingProposals = { data: action.payload, error: {}, loading: false };
    },
    fetchBusinessValuationError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.businessValuationPendingProposals = { data: {}, error: action.payload, loading: false };
    },
    saveBasicInfoStart: (state) => {
      state.saveBasicInfoPendingProposals = { data: {}, error: {}, loading: true };
    },
    saveBasicInfo: (state, action: PayloadAction<InitialStateInterface>) => {
      state.saveBasicInfoPendingProposals = { data: action.payload, error: {}, loading: false };
    },
    saveBasicInfoError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.saveBasicInfoPendingProposals = { data: {}, error: action.payload, loading: false };
    },
    resetSaveBasicInfoState: (state) => {
      state.saveBasicInfoPendingProposals = { data: {}, error: {}, loading: false };
      state.verifyDocPendingProposals = { data: {}, error: {}, loading: false };
    },
    verifyDocStart: (state) => {
      state.verifyDocPendingProposals = { data: {}, error: {}, loading: true };
    },
    verifyDocSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.verifyDocPendingProposals = { data: action.payload, error: {}, loading: false };
    },
    verifyDocError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.verifyDocPendingProposals = { data: {}, error: action.payload, loading: false };
    },
    getDocStart: (state) => {
      state.getDocPendingProposals = { data: {}, error: {}, loading: true };
    },
    getDocSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.getDocPendingProposals = { data: action.payload, error: {}, loading: false };
    },
    getDocError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.getDocPendingProposals = { data: {}, error: action.payload, loading: false };
    },
    deleteTaskStart: (state) => {
      state.deleteAdditionalTask = { data: {}, error: {}, loading: true };
    },
    deleteTaskSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.deleteAdditionalTask = { data: action.payload, error: {}, loading: false };
    },
    deleteTaskError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.deleteAdditionalTask = { data: {}, error: action.payload, loading: false };
    },
    generateInvoiceStart: (state) => {
      state.generateBasicInfoInvoice = { data: {}, error: {}, loading: true };
    },
    generateInvoiceSuccess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.generateBasicInfoInvoice = { data: action.payload, error: {}, loading: false };
    },
    generateInvoiceError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.generateBasicInfoInvoice = { data: {}, error: action.payload, loading: false };
    },
    deleteInvoiceStart: (state) => {
      state.deleteInvoice = { data: {}, error: {}, loading: true };
    },
    deleteInvoiceSuceess: (state, action: PayloadAction<InitialStateInterface>) => {
      state.deleteInvoice = { data: action.payload, error: {}, loading: false };
    },
    deleteInvoiceError: (state, action: PayloadAction<InitialStateInterface>) => {
      state.deleteInvoice = { data: {}, error: action.payload, loading: false };
    },
    resetDocData: (state) => {
      state.getDocPendingProposals = { data: {}, error: {}, loading: false };
    },
    resetBasicInfoState: (state) => {
      state.basicInfoPendingProposals = { data: {}, error: {}, loading: false };
    },
    resetPlanFinalize: (state) => {
      state.planFinalizePendingProposals = { data: {}, error: {}, loading: false };
    },
    resetInsurance: (state) => {
      state.insuranceUnderwritingPendingProposals = { data: {}, error: {}, loading: false };
    },
    resetGenerateInvoice: (state) => {
      state.generateBasicInfoInvoice = { data: {}, error: {}, loading: false };
    },
    resetDeleteInvoice: (state) => {
      state.deleteInvoice = { data: {}, error: {}, loading: false };
    },

    resetBusinessValuationState: (state) => {
      state.businessValuationPendingProposals = { data: {}, error: {}, loading: false };
      state.verifyDocPendingProposals = { data: {}, error: {}, loading: false };
    },
    resetDeleteTaskState: (state) => {
      state.deleteAdditionalTask = { data: {}, error: {}, loading: false };
    },
    resetVerifyDocSuccessState: (state) => {
      state.verifyDocPendingProposals = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchPendingProposalsSuccess,
  fetchPendingProposalsError,
  fetchPendingProposalsOngoing,
  fetchPendingInProgressProposalsOngoing,
  fetchPendingInProgressProposalsSuccess,
  fetchPendingInProgressProposalsError,
  fetchBasicInfoError,
  fetchBasicInfoStart,
  fetchBasicInfoSuccess,
  saveBasicInfo,
  saveBasicInfoError,
  saveBasicInfoStart,
  resetSaveBasicInfoState,
  verifyDocError,
  verifyDocStart,
  verifyDocSuccess,
  getDocError,
  getDocStart,
  getDocSuccess,
  resetDocData,
  updateOverallProgress,
  fetchInsuranceUnderwritingStart,
  fetchInsuranceUnderwritingSuccess,
  fetchPlanFinalizationStart,
  fetchInsuranceUnderwritingError,
  fetchPlanFinalizationSuccess,
  fetchPlanFinalizationError,
  fetchBusinessValuationStart,
  fetchBusinessValuationSuccess,
  fetchBusinessValuationError,
  deleteTaskStart,
  deleteTaskSuccess,
  deleteTaskError,
  deleteInvoiceStart,
  deleteInvoiceSuceess,
  deleteInvoiceError,
  generateInvoiceStart,
  generateInvoiceSuccess,
  generateInvoiceError,
  resetGenerateInvoice,
  resetDeleteInvoice,
  resetBasicInfoState,
  resetInsurance,
  resetPlanFinalize,
  resetDeleteTaskState,
  resetBusinessValuationState,
  resetVerifyDocSuccessState,
} = pendingProposalSlice.actions;
export const getAllPendingProposals = (state: RootState) => state.getPendingProposals;

export default pendingProposalSlice.reducer;
