import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import plansSaga from "./plansSaga";
import verifySaga from "./verifySaga";
import agentContactSaga from "./agentContactSaga";
import blockSaga from "./blockSaga";
import blockVerifySaga from "./blockVerifySaga";
import inviteEmailSaga from "./inviteEmailSaga";
import resendInviteSaga from "./resendInviteSaga";
import fetchUsersSaga from "./fetchUsersSaga";
import inviteAgencySaga from "./inviteAgencySaga";
import watchProfileEmailSaga from "./profileSaga";
import logoutSaga from "./logoutSaga";
import agentBrandingSaga from "./agentBrandingSaga";
import getAllAgentsSaga from "./getAllAgentsSaga";
import getAllAgenciesSaga from "./getAllAgenciesSaga";
import getAllPendingAgentsSaga from "./getAllPendingAgentsSaga";
import watchPostConfirmDetailsSaga from "./confirmDetailsSaga";
import requestProposalSaga from "./requestProposalSaga";
import proposalsSaga from "./proposalsSaga";
import resendResetPassSaga from "./getResendResetPasswordSaga";
import watchContactProfileLookupSaga from "./contactProfileLookupSaga";
import { watchStoreDashboard } from "./dashboardSaga";
import createRequestProposalSaga from "./createRequestProposalSaga";
import financialInfoSaga from "./financialInfoSaga";
import watchPatchPolicyInputBaseCSVUploadSaga from "./policyInputBaseCSVUploadSaga";
import watchInsuranceCompanies from "./insuranceCompaniesSaga";
import watchPostPolicyInputBaseCSVDataSaga from "./policyInputBaseCsvDataSaga";
import revokeContactByIdSaga from "./revokeContactByIdSaga";
import agentReviewContactSaga from "./agentReviewContactSaga";
import planAdminSplitDollarSaga from "./planAdminSplitDollarSaga";
import getPDFReportSaga from "./getPDFReportSaga";
import createProposalSaga from "./createProposalSaga";
import createBuySellProposalSaga from "./createBuySellProposalSaga";
import sendProposalSaga from "./sendProposalsaga";
import getAdminProfileSaga from "./getAdminProfileSaga";
import planSplitDollarSaga from "./planSplitDollarSaga";
import adminAgentsSaga from "./adminAgentsSaga";
import agentCommissionSaga from "./agentCommissionSaga";
import insuranceCompanySaga from "./insuranceCompanySaga";
import insuranceProductSaga from "./insuranceProductSaga";
import watchLoanPlanProjection from "./loanPlanProjectionSaga";
import { watchStoreIsFormActive } from "./isFormActiveSaga";
import revokeContactTimelineSaga from "./revokeContactTimeline";
import proposalReviewSaga from "./review-proposalSaga";
import watchAgentProfile from "./agentProfileSaga";
import proposalPendingSaga from "./pending-proposals";
import reqSignSaga from "./reqSignSaga";
import proposalActionReviewSaga from "./proposalActionReviewSaga";
import watchPostProposalsUploadSignedReportSaga from "./proposalsUploadSignedReportSaga";
import adminPendingApprovalSaga from "./adminPendingApprovalSaga";
import reviewDuplicateProposalIdSaga from "./duplicateProposalIDSaga";
import { watchStoreIsAgencyManager } from "./isAgencyMangerSaga";
import proposalsApprovedPendingSaga from "./proposalsApprovedPendingSaga";
import UploadDocumentSaga from "./uploadDocumentSaga";
import deleteUploadDocumentSaga from "./deleteUploadedDocumentSaga";
import planFinalizationSaga from "./planFinalization";
import additionUploadDocumentSaga from "./additionUploadDocumentSaga";
import updateDownloadFileStatusSaga from "./updateDocumentFileStatus";
import sendFileToContactSaga from "./sendFileToContact";
import watchAllPlansGraphViewSaga from "./plansGraphView";
import upcomingPlansSaga from "./upcomingPlansSaga";
import agentBillingSaga from "./agentBillingSaga";
import conciergeServiceSaga from "./agentConciergeServiceSaga";
import policySummarySaga from "./policySummary";
import viewPolicyDetailsSagas from "./viewPolicyDetailsSaga";
import watchAllPlanSummarySaga from "./planSummary";
import pendingDocumentListSaga from "./pendingDocumentList";
import viewPlanDetailsSagas from "./viewPlanDetailsSaga";
import watchCommissionPendingApprovalSaga from "./commissionPendingApprovalSaga";
import agencyCommissionSaga from "./agencyCommissionSaga";
import subscriptionCancelSagas from "./subscriptionCancelSaga";
import reportSaga from "./reportSaga";
import watchAllLoanSummarySaga from "./loanSummarySaga";
import afrSaga from "./AfrSaga";
import buySellSaga from "./buySellProposalSaga";
import proposedLifeLedgerSaga from "./proposedLifePolicyLedger";
import proposedDisabilityLedgerSaga from "./proposedDisabilityPolicyLedger";
import provisionSaga from "./provisionSaga";
import shareHolderSaga from "./shareholderLedgerSaga";
import disabilityInsuranceSaga from "./disabilityInsuranceSaga";
import lifeInsuranceUnderwritingSaga from "./lifeInsuranceUnderwritingSaga";
import policyReviewSaga from "./policyReviewSaga";
import createBsPlanSaga from "./createPlanSaga";
import valuationContributionReviewSaga from "./ValuationContributionReviewSaga";
import triggerEventsSaga from "./triggerEventsSaga";
import transactionLedgerSaga from "./transactionLedgerSaga";
import contactsSaga from "./contactsSaga";
import setAFRLoanSaga from "./setAFRLoanRateSaga";
import agentListingSaga from "./agentListingSaga";
import removeTeamMemberSaga from "./removeTeamMemberSaga";
import inviteTeamMemberSaga from "./inviteTeamMemberSaga";
import orgChartSaga from "./orgChartSaga";
import rolesSaga from "./rolesSaga";
import saAgencyRolesSagas from "./saAgencyViewRolesSaga";
import contactSummarySaga from "./contactRenameSaga";
import teamAssistantSaga from "./teamAssistantSaga";
import watchLoanReviewInvoiceSummarySaga from "./loanReviewInvoiceSaga";
import authSwitchSaga from "./authSwitchSaga";
import validateInviteSaga from "./validateInviteSaga";
import plansUpgradeSaga from "./planUpgradeSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    verifySaga(),
    plansSaga(),
    planSplitDollarSaga(),
    agentContactSaga(),
    agentReviewContactSaga(),
    fetchUsersSaga(),
    inviteAgencySaga(),
    inviteEmailSaga(),
    resendInviteSaga(),
    blockSaga(),
    blockVerifySaga(),
    watchProfileEmailSaga(),
    logoutSaga(),
    agentBrandingSaga(),
    getAllAgentsSaga(),
    getAdminProfileSaga(),
    getAllAgenciesSaga(),
    getAllPendingAgentsSaga(),
    watchPostConfirmDetailsSaga(),
    requestProposalSaga(),
    proposalsSaga(),
    resendResetPassSaga(),
    watchContactProfileLookupSaga(),
    watchStoreDashboard(),
    createRequestProposalSaga(),
    financialInfoSaga(),
    revokeContactByIdSaga(),
    revokeContactTimelineSaga(),
    watchPatchPolicyInputBaseCSVUploadSaga(),
    watchPostPolicyInputBaseCSVDataSaga(),
    watchInsuranceCompanies(),
    planAdminSplitDollarSaga(),
    getPDFReportSaga(),
    createProposalSaga(),
    createBuySellProposalSaga(),
    sendProposalSaga(),
    getAdminProfileSaga(),
    adminAgentsSaga(),
    agentCommissionSaga(),
    watchLoanPlanProjection(),
    watchStoreIsFormActive(),
    watchStoreIsAgencyManager(),
    proposalReviewSaga(),
    watchAgentProfile(),
    proposalPendingSaga(),
    reqSignSaga(),
    proposalActionReviewSaga(),
    watchPostProposalsUploadSignedReportSaga(),
    adminPendingApprovalSaga(),
    reviewDuplicateProposalIdSaga(),
    proposalsApprovedPendingSaga(),
    UploadDocumentSaga(),
    deleteUploadDocumentSaga(),
    planFinalizationSaga(),
    additionUploadDocumentSaga(),
    updateDownloadFileStatusSaga(),
    sendFileToContactSaga(),
    watchAllPlansGraphViewSaga(),
    upcomingPlansSaga(),
    agentBillingSaga(),
    conciergeServiceSaga(),
    policySummarySaga(),
    viewPolicyDetailsSagas(),
    viewPlanDetailsSagas(),
    watchAllPlanSummarySaga(),
    watchAllLoanSummarySaga(),
    pendingDocumentListSaga(),
    watchCommissionPendingApprovalSaga(),
    agencyCommissionSaga(),
    insuranceCompanySaga(),
    insuranceProductSaga(),
    subscriptionCancelSagas(),
    buySellSaga(),
    reportSaga(),
    afrSaga(),
    proposedLifeLedgerSaga(),
    proposedDisabilityLedgerSaga(),
    provisionSaga(),
    shareHolderSaga(),
    disabilityInsuranceSaga(),
    lifeInsuranceUnderwritingSaga(),
    policyReviewSaga(),
    createBsPlanSaga(),
    valuationContributionReviewSaga(),
    triggerEventsSaga(),
    transactionLedgerSaga(),
    contactsSaga(),
    setAFRLoanSaga(),
    agentListingSaga(),
    removeTeamMemberSaga(),
    inviteTeamMemberSaga(),
    orgChartSaga(),
    rolesSaga(),
    saAgencyRolesSagas(),
    contactSummarySaga(),
    teamAssistantSaga(),
    watchLoanReviewInvoiceSummarySaga(),
    authSwitchSaga(),
    validateInviteSaga(),
    plansUpgradeSaga(),
  ]);
}
