import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_AGENT_BRANDING_PRIMARY_LOGO_UPLOAD =
  "TRIGGER_AGENT_BRANDING_PRIMARY_LOGO_UPLOAD";
export const TRIGGER_AGENT_BRANDING_SECONDARY_LOGO_UPLOAD =
  "TRIGGER_AGENT_BRANDING_SECONDARY_LOGO_UPLOAD";

export const TRIGGER_GET_AGENT_BRANDING_DOMAIN = "TRIGGER_GET_AGENT_BRANDING_DOMAIN";

export const TRIGGER_AGENT_BRANDING_SAVE = "TRIGGER_AGENT_BRANDING_SAVE";
export const TRIGGER_AGENT_DELETE_LOGO = "TRIGGER_AGENT_DELETE_LOGO";

export const TRIGGER_GET_AGENT_BRANDING = "TRIGGER_GET_AGENT_BRANDING";
export const TRIGGER_GET_ALL_BRANDING = "TRIGGER_GET_ALL_BRANDING";
export const TRIGGER_ACTIVE_BRANDING = "TRIGGER_ACTIVE_BRANDING";
export const TRIGGER_SAVE_THEME_SETTINGS = "TRIGGER_SAVE_THEME_SETTINGS";
export const TRIGGER_SAVE_FONT_SETTINGS = "TRIGGER_SAVE_FONT_SETTINGS";
export const TRIGGER_STORE_SETTING_THEME = "TRIGGER_STORE_SETTING_THEME";

interface AgentBrandingInterface {
  dataUpload: any;
  errorUpload: boolean;
  loadingUpload: boolean;
  dataSecondaryUpload: any;
  errorSecondaryUpload: boolean;
  loadingSecondaryUpload: boolean;
  dataDeleteLogo: any;
  errorDeleteLogo: any;
  loadingDeleteLogo: boolean;
  dataBranding: any;
  agencyLogo: any;
  agencyLogoLoading: boolean;
  agencyLogoError: boolean;
  errorBranding: any;
  loadBranding: boolean;
  loadAgentBranding: boolean;
  subDomain?: any;
  errorSubDomain?: any;
  loadingSubDomain?: boolean;
  allBrandingData: any;
  allBrandingLoading: boolean;
  allBrandingError: any;
  activeBrandingSuccess: any;
  activeBrandingLoading: boolean;
  activeBrandingError: any;
  settingThemeData: any;
  settingThemeLoading: boolean;
  settingThemeError: any;
  settingFontData: any;
  settingFontLoading: boolean;
  settingFontError: any;
  settingThemeValue: any;
}

const initialState: AgentBrandingInterface = {
  dataUpload: null,
  errorUpload: false,
  loadingUpload: false,
  dataSecondaryUpload: null,
  errorSecondaryUpload: false,
  loadingSecondaryUpload: false,
  dataBranding: null,
  agencyLogo: null,
  agencyLogoLoading: false,
  agencyLogoError: false,
  errorBranding: null,
  loadBranding: false,
  loadAgentBranding: false,
  subDomain: null,
  errorSubDomain: null,
  loadingSubDomain: false,
  allBrandingData: null,
  allBrandingLoading: false,
  allBrandingError: false,
  activeBrandingSuccess: null,
  activeBrandingLoading: false,
  activeBrandingError: false,
  dataDeleteLogo: null,
  errorDeleteLogo: false,
  loadingDeleteLogo: false,
  settingThemeData: null,
  settingThemeLoading: false,
  settingThemeError: false,
  settingFontData: null,
  settingFontLoading: false,
  settingFontError: false,
  settingThemeValue: null,
};

export const agentBranding = createSlice({
  name: "agentBranding",
  initialState,
  reducers: {
    setUploadSuccess: (state, action: PayloadAction<any[]>) => {
      state.dataUpload = action.payload;
      state.loadingUpload = false;
      state.errorUpload = false;
    },
    setUploadFailure: (state, action: PayloadAction<string>) => {
      state.dataUpload = action.payload;
      state.loadingUpload = false;
      state.errorUpload = true;
    },
    setUploadOngoing: (state) => {
      state.dataUpload = null;
      state.loadingUpload = true;
      state.errorUpload = false;
    },
    setSecondaryUploadSuccess: (state, action: PayloadAction<any[]>) => {
      state.dataSecondaryUpload = action.payload;
      state.loadingSecondaryUpload = false;
      state.errorSecondaryUpload = false;
    },
    setSecondaryUploadFailure: (state, action: PayloadAction<string>) => {
      state.dataSecondaryUpload = action.payload;
      state.loadingSecondaryUpload = false;
      state.errorSecondaryUpload = true;
    },
    setSecondaryUploadOngoing: (state) => {
      state.dataSecondaryUpload = null;
      state.loadingSecondaryUpload = true;
      state.errorSecondaryUpload = false;
    },
    setAgentBrandingSuccess: (state, action: PayloadAction<any[]>) => {
      state.dataBranding = action.payload;
      state.loadBranding = false;
    },
    setAgentBrandingFailure: (state, action: PayloadAction<string>) => {
      state.errorBranding = action.payload;
      state.loadBranding = false;
    },
    setAgentBrandingOngoing: (state) => {
      state.dataBranding = null;
      state.loadBranding = true;
      state.errorBranding = null;
    },
    setGetAgentBrandingDetailsSuccess: (state, action: PayloadAction<any[]>) => {
      state.agencyLogo = action.payload;
      state.loadAgentBranding = false;
      state.agencyLogoLoading = false;
      state.agencyLogoError = false;
    },
    setGetAgentBrandingDetailsOngoing: (state) => {
      state.agencyLogo = null;
      state.loadAgentBranding = true;
      state.agencyLogoLoading = true;
      state.agencyLogoError = false;
    },
    setGetAgentBrandingDetailsFailure: (state, action: PayloadAction<string>) => {
      state.agencyLogo = null;
      state.agencyLogoLoading = false;
      state.agencyLogoError = true;
      state.loadAgentBranding = false;
      state.errorBranding = action.payload;
    },
    setSubDomainSuccess: (state, action: PayloadAction<any[]>) => {
      state.subDomain = action.payload;
      state.loadingSubDomain = false;
    },
    setSubDomainFailure: (state, action: PayloadAction<object>) => {
      state.errorSubDomain = action.payload;
      state.loadingSubDomain = false;
    },
    setSubDomainOngoing: (state) => {
      state.errorSubDomain = null;
      state.subDomain = null;
      state.loadingSubDomain = true;
    },
    resetAgentBrandingState: (state) => {
      state.dataBranding = null;
      state.loadBranding = false;
      state.errorBranding = false;
      state.agencyLogo = null;
      state.agencyLogoLoading = false;
      state.agencyLogoError = false;
      state.dataSecondaryUpload = null;
      state.loadingSecondaryUpload = false;
      state.errorSecondaryUpload = false;
      state.dataUpload = null;
      state.loadingUpload = false;
      state.errorUpload = false;
      state.subDomain = null;
      state.errorSubDomain = null;
      state.loadingSubDomain = false;
      state.dataDeleteLogo = null;
      state.loadingDeleteLogo = false;
      state.errorDeleteLogo = false;
    },
    setAllBrandingSuccess: (state, action: PayloadAction<any[]>) => {
      state.allBrandingData = action.payload;
      state.allBrandingLoading = false;
      state.allBrandingError = false;
    },
    setAllBrandingFailure: (state, action: PayloadAction<string>) => {
      state.allBrandingData = null;
      state.allBrandingLoading = false;
      state.allBrandingError = action.payload;
    },
    setAllBrandingOngoing: (state) => {
      state.allBrandingData = null;
      state.allBrandingLoading = true;
      state.allBrandingError = false;
    },
    setActiveBrandingSuccess: (state, action: PayloadAction<any[]>) => {
      state.activeBrandingSuccess = action.payload;
      state.activeBrandingLoading = false;
      state.activeBrandingError = false;
    },
    setActiveBrandingFailure: (state, action: PayloadAction<string>) => {
      state.activeBrandingSuccess = null;
      state.activeBrandingLoading = false;
      state.activeBrandingError = action.payload;
    },
    setActiveBrandingOngoing: (state) => {
      state.activeBrandingSuccess = null;
      state.activeBrandingLoading = true;
      state.activeBrandingError = false;
    },
    setDeleteLogoSuccess: (state, action: PayloadAction<any[]>) => {
      state.dataDeleteLogo = action.payload;
      state.loadingDeleteLogo = false;
      state.errorDeleteLogo = false;
    },
    setDeleteLogoFailure: (state, action: PayloadAction<string>) => {
      state.dataDeleteLogo = null;
      state.loadingDeleteLogo = false;
      state.errorDeleteLogo = action.payload;
    },
    setDeleteLogoOngoing: (state) => {
      state.dataDeleteLogo = null;
      state.loadingDeleteLogo = true;
      state.errorDeleteLogo = false;
    },
    resetAgentAgencyBrandingData: (state) => {
      state.dataBranding = null;
      state.loadBranding = false;
      state.errorBranding = false;
    },
    setSettingThemeSuccess: (state, action: PayloadAction<any[]>) => {
      state.settingThemeData = action.payload;
      state.settingThemeLoading = false;
      state.settingThemeError = false;
    },
    setSettingThemeFailure: (state, action: PayloadAction<string>) => {
      state.settingThemeData = null;
      state.settingThemeLoading = false;
      state.settingThemeError = action.payload;
    },
    setSettingThemeOngoing: (state) => {
      state.settingThemeData = null;
      state.settingThemeLoading = true;
      state.settingThemeError = false;
    },
    setSettingFontSuccess: (state, action: PayloadAction<any[]>) => {
      state.settingFontData = action.payload;
      state.settingFontLoading = false;
      state.settingFontError = false;
    },
    setSettingFontFailure: (state, action: PayloadAction<string>) => {
      state.settingFontData = null;
      state.settingFontLoading = false;
      state.settingFontError = action.payload;
    },
    setSettingFontOngoing: (state) => {
      state.settingFontData = null;
      state.settingFontLoading = true;
      state.settingFontError = false;
    },
    resetThemeSettings: (state) => {
      state.settingThemeData = null;
      state.settingThemeData = false;
      state.settingThemeData = false;
      state.settingFontData = null;
      state.settingFontData = false;
      state.settingFontData = false;
    },
    setThemeValue: (state, action: PayloadAction<any[]>) => {
      state.settingThemeValue = action.payload;
    },
    resetActiveBrandingSuccess: (state) => {
      state.activeBrandingSuccess = null;
      state.activeBrandingLoading = false;
      state.activeBrandingError = false;
    },
  },
});

export const {
  setUploadSuccess,
  setUploadFailure,
  setUploadOngoing,
  setSecondaryUploadSuccess,
  setSecondaryUploadFailure,
  setSecondaryUploadOngoing,
  setAgentBrandingSuccess,
  setAgentBrandingFailure,
  setAgentBrandingOngoing,
  resetAgentBrandingState,
  setGetAgentBrandingDetailsSuccess,
  setGetAgentBrandingDetailsFailure,
  setGetAgentBrandingDetailsOngoing,
  setSubDomainSuccess,
  setSubDomainFailure,
  setSubDomainOngoing,
  setAllBrandingSuccess,
  setAllBrandingFailure,
  setAllBrandingOngoing,
  setActiveBrandingSuccess,
  setActiveBrandingFailure,
  setActiveBrandingOngoing,
  setDeleteLogoSuccess,
  setDeleteLogoFailure,
  setDeleteLogoOngoing,
  resetAgentAgencyBrandingData,
  setSettingThemeSuccess,
  setSettingThemeFailure,
  setSettingThemeOngoing,
  setSettingFontSuccess,
  setSettingFontFailure,
  setSettingFontOngoing,
  setThemeValue,
  resetThemeSettings,
  resetActiveBrandingSuccess,
} = agentBranding.actions;
export const getAgentBranding = (state: RootState) => state.getAgentBrandingDetails;

export const agentBrandingReducer = agentBranding.reducer;
