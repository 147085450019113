import { TRIGGER_LOGOUT_REQUEST, logoutSucess, logoutRequestError, setFCMToken } from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* logoutRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;

    const response = yield call(() => makeRequest(path).post());
    yield put(logoutSucess(response.data));
    yield put(setFCMToken(false));
  } catch (error: any) {
    yield put(logoutRequestError(error));
  }
}

function* watchLogoutRequest() {
  yield takeLatest(TRIGGER_LOGOUT_REQUEST, logoutRequest);
}

export default function* logoutSaga() {
  yield all([watchLogoutRequest()]);
}
